import React, { useEffect, useState, useRef, useCallback } from "react";
import getimg from "../Gettoknow/getimg.png";
import { useMediaQuery } from "react-responsive";
import plusimg from "../Gettoknow/plusimg.png";
import msgimg from "../Gettoknow/msgimg.png";
import callimg from "../Gettoknow/callimg.png";
import NewNav from "../../components/NewNav/Newnavbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserIcon from '../../assets/user.png'

function Gettoknow() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");

  const [users, setUsers] = useState([]);
  const [visibleUsers, setVisibleUsers] = useState(5); // Load first 5 users
  const [loading, setLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false)
  const observer = useRef();

  // Fetch users and apply lazy loading
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await fetch("https://skill-guru-backend.vercel.app/users/users");
        if (!response.ok) throw new Error("Failed to fetch users");

        const data = await response.json();
        const filteredGurus = data.users
          .filter((user) => user.roles?.includes("Guru"))
          .sort((a, b) => (b.rating_as_guru || 0) - (a.rating_as_guru || 0));

        setUsers(filteredGurus);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersData();
  }, []);

  // Function to handle lazy loading
  const lastUserRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && visibleUsers < users.length) {
          setLoading(true);
          setTimeout(() => {
            setVisibleUsers((prev) => prev + 5); // Load next 5 users
            setLoading(false);
          }, 1000);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, visibleUsers, users.length]
  );
  const fetchToken = async (channelName) => {
    try {
      const response = await fetch(`https://skillgurubackend-1.vercel.app/generate-token?channelName=${channelName}`);
      if (!response.ok) throw new Error("Failed to fetch token");

      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error("Error fetching token:", error);
      toast.error("Failed to fetch token.");
      return null;
    }
  };

  // Call functionality
  const handleCall = async (fcmToken) => {
    try {
      const uid = localStorage.getItem('uid')
      const channelName = `channel_${uid}`;
      console.log("channelName:", channelName)
      setTokenLoading(true)
      const token = await fetchToken(channelName);
      if (!token) {
        setTokenLoading(false)
        return;
      }
      const agoraToken = token;

      console.log("Token :", agoraToken)


      const response = await fetch("https://skill-guru-backend.vercel.app/notification/send-notification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: fcmToken,
          title: "Incoming Call",
          body: "A student needs help with Three.js. Click to join the call.",
          callerId: uid,
          channelName: channelName,
          question: "",
          agoraToken: agoraToken,
        }),
      });

      const result = await response.json();
      console.log("Notification sent:", result);
      setTokenLoading(false)
      navigate("/video-call", { state: { channelName, token } });
      toast.success("Call notification sent to Three.js Gurus!");
    } catch (error) {
      console.error("Error sending notifications:", error);
      toast.error("Failed to send notifications.");
    }
  };

  return (
    <>

      <div style={{ background: "linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)", height: isMobile ? "100%" : "222px" }}>
        <div className="container">
          <div className="row featurette" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="col-md-7">
              <h1 className="featurette-heading">
                <span style={{ color: "rgba(49, 58, 96, 1)", fontWeight: "600", fontSize: isMobile ? "34px" : "54px" }}>Get to Know Your </span>
                <span
                  style={{
                    background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 72.38%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "600",
                    fontSize: isMobile ? "34px" : "54px",
                  }}
                >
                  Guru
                </span>
              </h1>
              <p className="lead" style={{ color: "rgba(95, 105, 128, 1)", fontSize: isMobile ? "15px" : "24px", fontWeight: "500" }}>The Mentor Behind the Mastery</p>
            </div>
            <div className="col-md-5 text-end">
              <img src={getimg} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" style={{ width: isMobile ? "300px" : "346px" }} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Guru Count */}
      <div className="container mt-4">
        <p style={{ color: "rgba(104, 104, 104, 1)", fontSize: "20px", textAlign: "center", lineHeight: "20px", fontWeight: "400", marginTop: "50px" }}>
          {users.length} expert Gurus
        </p>
      </div>

      {/* Guru List */}
      <div className="container" style={{ marginTop: "50px", display: "flex", flexDirection: "column", gap: "20px" }}>
        {users.slice(0, visibleUsers).map((user, index) => (
          <div key={user.id} className="row mb-1" ref={index === visibleUsers - 1 ? lastUserRef : null}>
            <div className="col-md-12">
              <div style={{ borderRadius: "30px", border: "1px solid gray", display: "flex", flexDirection: isMobile ? "column" : "", overflow: "hidden" }}>
                <div className="col-auto d-lg-block">
                  <img className="bd-placeholder-img" src={!user.profileImageUrl ? UserIcon : user.profileImageUrl} alt="" style={{ width: isMobile ? "100%" : "400px", height: !user.profileImageUrl ? isMobile ? "200px" : "70%" : isMobile ? "400px" : "100%", borderRadius: isMobile ? "30px" : "", objectFit: "contain" }} />
                </div>

                <div className="col d-flex flex-column position-static" style={{ padding: isMobile ? "10px" : "40px" }}>
                  <div style={{display:"flex",gap:"20px"}}>
                    <h3 className="mb-0" style={{ fontSize: isMobile ? "28px" : "32px", fontWeight: "500" }}>{user.fullName}</h3>
                    <p className="mb-0" style={{ fontSize: isMobile ? "28px" : "32px", fontWeight: "500" }}>{user['Is online']?"Online":"Offline"}</p>
                  </div>


                  <p>
                    <span style={{ color: "rgba(0, 0, 0, 1)", fontSize: "20px", fontWeight: "500" }}>Skills: </span>
                    {user["Guru skills"]?.slice(0, 3).join(", ")}
                    {user["Guru skills"]?.length > 3 && <span>...</span>}
                  </p>

                  {/* Call & Message Buttons */}
                  <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
                    {/* <div style={{ background: "rgba(91, 114, 169, 1)", padding: "8px 25px", borderRadius: "30px", display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <span style={{ fontSize: "24px", color: "white" }}>Message</span>
                      <img src={msgimg} alt="" style={{ height: "15px", marginLeft: "8px" }} />
                    </div> */}
                    {!tokenLoading ? (
                      <div onClick={() => handleCall(user.fcmToken)} style={{ background: "rgba(69, 227, 98, 1)", padding: "8px 25px", borderRadius: "30px", display: "flex", alignItems: "center", cursor: "pointer" }}>
                        <span style={{ fontSize: "24px", color: "white" }}>Call</span>
                        <img src={callimg} alt="" style={{ height: "15px", marginLeft: "8px" }} />
                      </div>
                    ) : (
                      <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {loading && <p>Loading more gurus...</p>}
      </div>
    </>
  );
}

export default Gettoknow;
