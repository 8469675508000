import React from 'react';
import playstore from "../image/play store.png";
import { useMediaQuery } from 'react-responsive';
import logo from '../NavbarNew/logo.png'
import { useNavigate } from 'react-router-dom';

function Footer() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const navigate = useNavigate();
    return (
        <>
            <div >
                <br />
                <br />
                <div className='container' >
                    <a href="#" className="navbar-brand ">
                        <div className="logosection" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="logoimg">
                                <img src={logo} alt="" style={{ height: '50px', marginLeft: '-8px' }} id='logo' />
                            </div>
                            <div className="skillguru ">
                                <p style={{
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '25px',
                                    fontWeight: '600',
                                    color: 'linear-gradient(90deg, #C34D1E 0%, #314471 100%),',
                                    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                    margin: '0',
                                    letterSpacing: '0.05em'
                                }}>Skill Guru</p>
                            </div>
                        </div>
                    </a>
                    <p className="lead empower" style={{ width: isMobile ? '100%' : '393px' }}>Empowering minds, transforming lives through education</p>
                    <br />
                    <hr />
                    <footer className="footer ">
                        <div className="rows">
                            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : '', justifyContent: 'space-between', width: '100%', gap: '30px' }}>
                                <div style={{ display: 'flex', gap: isMobile ? '0' : '120px', width: isMobile ? '100%' : '', justifyContent: isMobile ? 'space-around' : '' }}>
                                    <div>
                                        <p style={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: '700', fontSize: '22px', lineHeight: '27px' }}>Product</p>

                                        <p style={{ listStyle: 'none' }}>
                                            <a href="#" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Home</a>
                                        </p>
                                        <p style={{ listStyle: 'none' }}>
                                            <a href="/questions" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Ask Question</a>
                                        </p>
                                        <p style={{ listStyle: 'none' }}>
                                            <a href="#" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Community</a>
                                        </p>
                                        <p style={{ listStyle: 'none' }}>
                                            <a href="#" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Volunteer</a>
                                        </p>
                                        <p style={{ listStyle: 'none' }}>
                                            <a href="#" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Messages</a>
                                        </p>
                                        <p style={{ listStyle: 'none' }}>
                                            <a href="#" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Profile</a>
                                        </p>
                                       


                                    </div>
                                    <div>
                                        <p style={{ color: 'rgba(73, 40, 40, 0.8)', fontWeight: '700', fontSize: '22px', lineHeight: '27px' }}>Company</p>
                                        <p>
                                            <a href="/about-us" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>About Us</a>
                                        </p>
                                        <p>
                                            <a href="/privacy" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Privacy Policy</a>
                                        </p>
                                        <p>
                                            <a href="/team" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Teams</a>
                                        </p>
                                        <p>
                                            <a href="/faqs" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>FAQs</a>
                                        </p>
                                        <p>
                                            <a href="/contact-us" style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Contact Us</a>
                                        </p>
                                        <p>
                                            <a href="https://rzp.io/rzp/3hDhG8T" target='_blank' style={{ textDecoration: 'none', color: 'rgba(67, 67, 67, 1)', fontSize: '18px' }}>Donate Us</a>
                                        </p>

                                    </div>
                                </div>
                                <div style={{ marginTop: '30px' }}>
                                    <div style={{ display: 'flex', justifyContent: isMobile ? 'start' : 'end' }}>
                                        <div style={{ width: isMobile ? '100%' : isTablet ? '' : '448px', display: 'flex', justifyContent: 'space-between', padding: '8px 16px', borderRadius: '10px', background: 'rgba(243, 244, 255, 1)' }}>
                                            <input type="text" placeholder='Email' style={{width:"70%", fontSize: isMobile?'15px':"18px", fontWeight: '400', padding: '4px', border: 'none', outline: 'none', background: 'transparent' }} />
                                            <button onClick={()=>{navigate('/signup')}} style={{ fontSize: '15px', background: 'rgba(72, 94, 146, 1)', borderRadius: '10px', color: 'rgba(255, 255, 255, 1)', fontWeight: '400', outline: 'none', border: 'none', padding: '6px 13px' }}>Subscribe</button>
                                        </div>
                                    </div>
                                    <h2 style={{ textAlign: isMobile ? 'left' : 'end', marginTop: '20px' ,fontWeight:"600"}}>Download Mobile App</h2>
                                    <div style={{ display: 'flex', justifyContent: isMobile ? 'left' : 'end', gap: '15px', marginTop: '20px', marginBottom: '20px' }}>
                                        
                                        <a href='https://play.google.com/store/apps/details?id=com.skillguru.mainskillguru111&hl=en' target='_blank' ><img src={playstore} alt="" /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </footer>
                </div>
                <div style={{ background: 'linear-gradient(90deg, #BAC1DA 0%, #F0C9CE 100%)', height: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '18px', fontWeight: '400', letterSpacing: '9%', color: 'rgba(49, 58, 96, 1)', padding: '15px 10px 5px 10px', textAlign: 'center' }}>© 2025 Skills Guru Foundation. All Rights Reserved.</p>
                </div>
            </div>

        </>
    )
}

export default Footer