import React from 'react';
import { useMediaQuery } from 'react-responsive';
import privacy from '../assets/privacy_img.png'
import Newnavbar from '../components/NewNav/Newnavbar';
import Navbar from '../components/NavbarNew/Navbar';
import Footer from '../components/Footer/Footer';

function Privacy() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isDesktop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const uid = localStorage.getItem('uid')
    return (
        <div >
            <div className="background-circles">
                <div className="circle one"></div>
                <div className="circle two"></div>
                <div className="circle-small" ></div>
            </div>

            <div style={{ background: 'linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)', height: isMobile ? '100%' : '232px' }}>
                <div className="container">
                    {/* img section */}
                    <div className="row featurette " style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', alignItems: 'center', textAlign: isMobile ? 'center' : '' }}>
                        <div className="col-md-7">
                            <h1 className="featurette-heading">
                                <span style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '600', fontSize: isMobile ? '34px' : '50px' }}>Privacy Policy</span>
                            </h1>
                        </div>
                        <div className="col-md-5 text-end ">
                            <img src={privacy} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', width: isMobile ? '300px' : '346px' }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Teaxt section */}
            <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginTop: '66px', width: '1000px' }}>
                    <p style={{ fontWeight: '400', fontSize: '20px', color: '#000000' }}>At The Skill Guru Foundation, we are deeply committed to protecting the privacy of all individuals who interact with our platform, including children, parents, and educators. This Privacy Policy outlines how we collect, use, and safeguard your information in compliance with applicable laws, including child protection laws.</p>
                    {/* Information We Collect */}
                    <h1 className="featurette-heading">
                        <span style={{
                            color: '#C34D1E',
                            fontWeight: '700', fontSize: isMobile ? '24px' : '32px'
                        }}>1. </span>
                        <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>Information We Collect</span>
                    </h1>
                    <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>We collect the following types of information :</p>
                    <p style={{ fontWeight: '600', fontSize: isMobile ? '24px' : '28px', color: '#000000', marginTop: '20px' }}>a) From Children :
                    </p>
                    <ul style={{ marginTop: '20px' }}>
                        <li style={{ color: '#000000', fontSize: '18px' }}>
                            <b>Personal Information:</b> Limited data such as name, age, and school affiliation, collected with parental consent as per the Children’s Online Privacy Protection Act (COPPA).
                        </li>
                        <li style={{ color: '#000000', fontSize: '18px' }}>
                            <b>Usage Data:</b>  Information on how children interact with our platform, such as learning progress and preferences.
                        </li>
                    </ul>
                    <p style={{ fontWeight: '600', fontSize: isMobile ? '24px' : '28px', color: '#000000', marginTop: '20px' }}>b) From Children :
                    </p>
                    <ul style={{ marginTop: '20px' }}>
                        <li style={{ color: '#000000', fontSize: '18px' }}>
                            <b>Personal Information:</b> Contact details, account information, and payment details (if applicable).
                        </li>
                        <li style={{ color: '#000000', fontSize: '18px' }}>
                            <b>Professional Information:</b> Teaching credentials or related details for educators.
                        </li>
                    </ul>
                    <p style={{ fontWeight: '600', fontSize: isMobile ? '24px' : '28px', color: '#000000', marginTop: '20px' }}>c) From Visitors :
                    </p>
                    <ul style={{ marginTop: '20px' }}>
                        <li style={{ color: '#000000', fontSize: '18px' }}>
                            <b>Cookies and Analytics:</b> Data to improve user experience.
                        </li>
                    </ul>
                    {/* User Information */}
                    <h1 className="featurette-heading">
                        <span style={{
                            color: '#C34D1E',
                            fontWeight: '700', fontSize: isMobile ? '24px' : '32px'
                        }}>2. </span>
                        <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>User Information </span>
                    </h1>
                    <ul style={{ marginTop: '20px' }}>
                        <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>Enhance the learning experience.</li>
                        <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>Communicate with parents, educators, and children. </li>
                        <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>Improve our platform and services.</li>
                        <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>Comply with legal requirements.</li>
                    </ul>
                    {/* Data Security  */}
                    <h1 className="featurette-heading">
                        <span style={{
                            color: '#C34D1E',
                            fontWeight: '700', fontSize: isMobile ? '24px' : '32px'
                        }}>3. </span>
                        <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>Data Security  </span>
                    </h1>
                    <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>We employ industry-standard security measures, including encryption and regular audits, to protect personal data.</p>
                    {/* Rights of Children and Parents  */}
                    <h1 className="featurette-heading">
                        <span style={{
                            color: '#C34D1E',
                            fontWeight: '700', fontSize: isMobile ? '24px' : '32px'
                        }}>4. </span>
                        <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>Rights of Children and Parents  </span>
                    </h1>
                    <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>Parents can review, delete, or restrict data about their children. Children’s data is collected only with parental consent.
                    </p>
                    {/* Sharing Of Information */}
                    <h1 className="featurette-heading">
                        <span style={{
                            color: '#C34D1E',
                            fontWeight: '700', fontSize: isMobile ? '24px' : '32px'
                        }}>5. </span>
                        <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>Sharing Of Information </span>
                    </h1>
                    <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>We do not sell or share personal data with third parties, except when required by law or with explicit consent.
                    </p>
                    {/*Terms and Conditions */}
                    <h1 className="featurette-heading">
                        <span style={{
                            color: '#C34D1E',
                            fontWeight: '700', fontSize: isMobile ? '24px' : '32px'
                        }}>6. </span>
                        <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>Terms and Conditions </span>
                        <p style={{ fontWeight: '600', fontSize: '22px', color: '#000000', marginTop: '20px' }}>a) Acceptance of Terms
                        </p>
                        <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>By accessing our platform, users agree to these terms. Parents and educators are responsible for ensuring children’s compliance.
                        </p>
                        <p style={{ fontWeight: '600', fontSize: '22px', color: '#000000', marginTop: '20px' }}>b) Account Responsibilities                        </p>
                        <ul style={{ marginTop: '20px' }}>
                            <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>	Users must keep account details secure. </li>
                            <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>	Parents/educators must monitor children’s activity.                            </li>
                        </ul>
                        <p style={{ fontWeight: '600', fontSize: '22px', color: '#000000', marginTop: '20px' }}>c) Prohibited Activities</p>
                        <ul style={{ marginTop: '20px' }}>
                            <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>Misuse of platform resources </li>
                            <li style={{ fontWeight: '400', fontSize: '18px', color: '#000000' }}>Sharing harmful or inappropriate content.                            </li>
                        </ul>
                        <p style={{ fontWeight: '600', fontSize: '22px', color: '#000000', marginTop: '20px' }}>d) Intellectual Property
                        </p>
                        <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>All content on our platform is the property of The Skill Guru Foundation and is protected by intellectual property laws.
                        </p>
                        <p style={{ fontWeight: '600', fontSize: '22px', color: '#000000', marginTop: '20px' }}>e) Limitation of Liability
                        </p>
                        <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>We are not liable for indirect damages arising from the use of our platform.
                        </p>
                        <p style={{ fontWeight: '600', fontSize: '22px', color: '#000000', marginTop: '20px' }}>f) Governing Law
                        </p>
                        <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>These terms are governed by [Applicable Jurisdiction].
                        </p>
                        {/* About us */}
                        <h1 className="featurette-heading">
                            <span style={{ color: '#001137', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>About Us</span>
                            <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>The Skill Guru Foundation is dedicated to fostering a safe, innovative, and inclusive learning environment for children. Our platform empowers students, educators, and parents by providing cutting-edge tools and resources designed to inspire and educate.
                                Our Mission</p>
                            <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>To revolutionize education by combining technology, creativity, and a child-centric approach. Our Values
                            </p>
                            <ul style={{ marginTop: '20px' }}>
                                <li style={{ color: '#000000', fontSize: '18px' }}>
                                    <b>Safety :</b>  Ensuring the security and well-being of children is our top priority.
                                </li>
                                <li style={{ color: '#000000', fontSize: '18px' }}><b>Inclusion :</b> Providing equitable opportunities for all.</li>
                                <li style={{ color: '#000000', fontSize: '18px' }}>
                                    <b>Innovation :</b> Continuously evolving to meet the needs of 21st-century learners.
                                </li>
                            </ul>
                            <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>Commitment to Government Collaboration
                            </p>
                            <p style={{ fontWeight: '400', fontSize: '18px', color: '#000000', marginTop: '20px' }}>We actively collaborate with regulatory bodies to ensure compliance with child safety and education standards. This partnership underscores our dedication to legal and ethical practices.
                            </p>
                        </h1>
                    </h1>

                </div>
            </div>



            <Footer />
        </div>
    )
}

export default Privacy