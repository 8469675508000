import React, { useState } from 'react';
import PurpleCircle from '../components/PurpleCircle';
import PinkCircle from '../components/PinkCircle';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const SelectRole = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleRoleSelection = (role) => {
    setSelectedRoles((prevRoles) => {
      // Add role if it's not already selected, otherwise remove it
      if (prevRoles.includes(role)) {
        return prevRoles.filter((r) => r !== role); // Remove the role
      } else {
        return [...prevRoles, role]; // Add the role
      }
    });
  };




  const saveRolesToDatabase = async () => {
    console.log(localStorage.getItem('uid'))
    try {
      const response = await fetch('https://skill-guru-backend.vercel.app/save-roles/save-roles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: localStorage.getItem('uid'), // Assuming user ID is stored in localStorage
          selectedRoles,
        }),
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log('Roles saved successfully:', data);
        if(selectedRoles.length>1){
          navigate('/select-both-skills')
        }else{
          navigate('/select-skills', { state: { selectedRoles } });
        }
      } else {
        console.error('Error saving roles:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const handleNext = async() => {
    console.log('Selected Roles:', selectedRoles);
    await saveRolesToDatabase()
    
    
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Background Circles */}
      <div className="signup-circle1">
        <PurpleCircle />
      </div>
      <div className="signup-circle2">
        <PinkCircle />
      </div>

      {/* Select Role Card */}
      <div
        className="select-role-card"
        style={{
          background: 'var(--GLASS, rgba(255, 255, 255, 0.41))',
          backdropFilter: 'blur(50px)',
          boxShadow: '0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)',
          width: !isMobile ? '494px' : '354px',
          height: !isMobile ? '664px' : '530px',
          position: 'absolute',
          zIndex: 2,
          top: !isMobile ? '50%' : '70%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '28.46px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>
          <img
            src="https://s3-alpha-sig.figma.com/img/757c/cc3f/aae7b0df685d3564b13a0210a37ff70e?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=D3LJFKZ1kJ0BS5bFL6nXHxr6-zub4UU5x7pSfFP9A3-BbvxHPGHDIrxpRn3SqlY7J9lXHjVB7Q2fv2oH6CaWcHgkIhMeYTT3nLHBfmXm9IVESyGv4zUeOj~G08Q2KHji3wuYGXG9l5Hs47PmiG0iSdqCh~DIhtvHsM9wvqdFIG5xmMMKAsSZfQG9IuXiPpXoKVJS7Uf6hdaB2xwGhkqNw1KhXz7RbKa7-TIVqvvtOGG1H039Jky1cvig6ZNT9EQN9DLErDhmkU411A1mBiDhZ~7bh2ZqQdZX0~7oR2LjgfBtvWkQ~-LlE3qGhqig-VJwP~twQX1LGiiBMXkAbqTUyw__"
            style={{ width: '45px', height: '64px' }}
            alt="logo"
          />
        </div>
        <div>
          <p className="colored-text" style={{ fontSize: isMobile ? '12px' : '' }}>
            Would you consider yourself a
          </p>
        </div>
        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Guru Role */}
          <div
            onClick={() => handleRoleSelection('Guru')}
            style={{
              width: !isMobile ? '140px' : '70px',
              height: !isMobile ? '151px' : '75px',
              padding: '10px 24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: selectedRoles.includes('Guru') ? 'rgba(72, 94, 146, 1)' : 'rgba(244,243,250,255)',
              borderRadius: '30px',
              color: selectedRoles.includes('Guru') ? 'white' : 'rgba(72, 94, 146, 1)',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
            }}
          >
            <p style={{ fontSize: '20px', fontWeight: '500' }}>Guru?</p>
          </div>
          {/* Learner Role */}
          <div
            onClick={() => handleRoleSelection('Learner')}
            style={{
              width: !isMobile ? '140px' : '70px',
              height: !isMobile ? '151px' : '75px',
              padding: '10px 24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: selectedRoles.includes('Learner') ? 'rgba(72, 94, 146, 1)' : 'rgba(244,243,250,255)',
              borderRadius: '30px',
              color: selectedRoles.includes('Learner') ? 'white' : 'rgba(72, 94, 146, 1)',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
            }}
          >
            <p style={{ fontSize: '20px', fontWeight: '500' }}>Learner?</p>
          </div>
        </div>
        <button
          onClick={handleNext}
          style={{
            width: !isMobile ? '377px' : '235px',
            height: '40px',
            background: 'rgba(72, 94, 146, 1)',
            borderRadius: '10px',
            color: 'white',
            marginTop: '60px',
            cursor:"pointer"
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectRole;
