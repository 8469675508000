import React, { useEffect, useState } from 'react';
import Newnavbar from '../components/NewNav/Newnavbar';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import PorfileImg from '../assets/material-symbols_person.svg';
import Star from '../assets/Star 5.svg'
import Call from '../assets/Vector.svg'
import Problems from '../assets/problems.svg'
import Live from '../assets/Live.svg'
import Referral from '../assets/referral.svg'
import { toast } from 'react-toastify';

const Profile = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [user, setUser] = useState({});
    const [recentCallers, setRecentCallers] = useState([]);
    const [isLoading,setIsLoading] = useState(false)
    const uid = localStorage.getItem('uid');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${uid}`);
                if (!response.ok) throw new Error("Failed to fetch user details");
                const data = await response.json();
                setUser(data.user);
                console.log(data.user.recent_guru_callids)
                fetchRecentCallers(data.user.recent_guru_callids || []);  // Fetch recent callers
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        const fetchRecentCallers = async (callIds) => {
            try {
                const callerNames = await Promise.all(
                    callIds.map(async (callId) => {
                        const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${callId}`);
                        if (!response.ok) return null;
                        const data = await response.json();
                        return data.user || [];  // Adjust key based on API response
                    })
                );
                setRecentCallers(callerNames); // Remove null values
            } catch (error) {
                console.error("Error fetching caller details:", error);
            }
        };

        if (uid) fetchUserData();
    }, [uid]);

    const fetchToken = async (channelName) => {
        try {
            const response = await fetch(`https://skillgurubackend-1.vercel.app/generate-token?channelName=${channelName}`);
            if (!response.ok) throw new Error("Failed to fetch token");

            const data = await response.json();
            return data.token;
        } catch (error) {
            console.error("Error fetching token:", error);
            toast.error("Failed to fetch token.");
            return null;
        }
    };


    const handleCall = async (receiver) => {
        const uid = localStorage.getItem("uid");
        const channelName = `channel_${uid}`;

        try {
            setIsLoading(true)
            
            const token = await fetchToken(channelName);
            if (!token) return;

            const agoraToken = token;
            console.log(agoraToken);

            await storeCallDetails(channelName, token, "", uid);
           

            // Send Notification to the receiver
            const notificationPayload = {
                token: receiver.fcmToken,
                receiverId: receiver.id,
                title: "Incoming Call",
                body: `You have an incoming call from ${user.fullName}. Click to join.`,
                callerId: uid,
                channelName: channelName,
                agoraToken: agoraToken,
                question: ""
            };

            const response = await fetch("https://skill-guru-backend.vercel.app/notification/send-notification", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(notificationPayload),
            });

            const result = await response.json();
            console.log("Notification Response:", result);
            toast.success("Call notification sent!");
            setIsLoading(false)
            navigate("/video-call", { state: { channelName, token } });
        } catch (error) {
            console.error("Error sending call notification:", error);
            toast.error("Failed to send notification.");
            setIsLoading(false)
        }
    };
    const storeCallDetails = async (channelName, token, question) => {
        const uid = localStorage.getItem('uid');
        const callDetails = { channelName, token, question }
        try {
            const response = await fetch("https://skill-guru-backend.vercel.app/call-details/call-details", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ callDetails, uid })
            });
            const text = await response.text(); // Get raw response
            console.log("Raw Response:", text);

            try {
                const data = JSON.parse(text);
                console.log("Parsed JSON:", data);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
            }
        } catch (error) {
            console.error("Error saving call details:", error);
        }
    };



    return (
        <div>
           
            <div className="container" style={{ marginTop: "100px" }}>

                {/* Header */}
                <div style={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "space-between",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    textAlign: isMobile ? "center" : "left",
                }}>
                    <h1 style={{
                        background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 72.38%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: '600',
                        fontSize: isMobile ? '27px' : '48px'
                    }}>
                        My Profile
                    </h1>

                    {/* Edit Profile */}
                    {!isMobile && (
                        <div onClick={() => navigate('/edit-profile', { state: { user } })} >

                            <Link style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "#111B47" }} onClick={() => navigate('/edit-profile')} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
                                    <path d="M14.3432 9.02L15.2632 9.94L6.2032 19H5.2832V18.08L14.3432 9.02ZM17.9432 3C17.6932 3 17.4332 3.1 17.2432 3.29L15.4132 5.12L19.1632 8.87L20.9932 7.04C21.3832 6.65 21.3832 6.02 20.9932 5.63L18.6532 3.29C18.4532 3.09 18.2032 3 17.9432 3ZM14.3432 6.19L3.2832 17.25V21H7.0332L18.0932 9.94L14.3432 6.19Z" fill="#001137" />
                                </svg>
                                <p className='mt-3' style={{ marginLeft: "5px" }}>Edit profile</p>
                            </Link>
                        </div>
                    )}
                </div>

                {/* Profile Card */}
                <div className="container" style={{
                    width: "100%",
                    borderRadius: "30px",
                    background: "#FFF",
                    boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                    backdropFilter: "blur(25px)",
                    padding: isMobile ? "20px" : "40px",
                    textAlign: isMobile ? "center" : "left"
                }}>

                    {/* Background Cover */}
                    <div style={{
                        borderRadius: "30px 30px 0px 0px",
                        backgroundImage: "url('https://imgs.search.brave.com/q3Y955EeSj8jvmVAB-D4a1v5qkZZvh-jgTRCMAjfVJU/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9tYXJr/ZXRwbGFjZS5jYW52/YS5jb20vRUFFdFdP/RnhEdzQvMi8wLzE2/MDB3L2NhbnZhLXBp/bmstaWxsdXN0cmF0/ZWQtc3RyYXdiZXJy/eS1kZXNrdG9wLXdh/bGxwYXBlci1TOXlE/bkRnREhmOC5qcGc')",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "120px",
                        width: "100%"
                    }} />

                    {/* Profile Image */}
                    <div style={{
                        display: "flex",
                        justifyContent: isMobile ? "center" : "flex-start",
                        marginTop: "-50px",
                    }}>
                        <img className='rounded-circle' style={{
                            height: "90px",
                            width: "90px",
                            background: !user['profileImageUrl'] ? 'pink' : "",
                        }} src={user['profileImageUrl'] || PorfileImg} alt="Profile" />
                    </div>

                    {/* User Details */}
                    <div style={{ marginLeft: isMobile ? "0" : "170px", textAlign: isMobile ? "center" : "left" }}>
                        <h3>{user.fullName}</h3>
                        <p style={{ color: user['Is online'] ? '#45E362' : 'gray' }}>
                            {user['Is online'] ? 'Online' : 'Offline'}
                        </p>

                        {/* Roles & Skills */}
                        <div style={{ textAlign: isMobile ? "center" : "left" }}>
                            {user?.roles?.includes('Guru') && (
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: isMobile ? "center" : "flex-start" }}>
                                    <p style={{ fontWeight: "bold" }}>Guru:</p>
                                    {user['Guru skills']?.map((skill, index) => (
                                        <p key={index} className="mx-1">{skill}</p>
                                    ))}
                                </div>
                            )}

                            {user?.roles?.includes('Learner') && (
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: isMobile ? "center" : "flex-start" }}>
                                    <p style={{ fontWeight: "bold" }}>Learner:</p>
                                    {user['Learner skills']?.map((skill, index) => (
                                        <p key={index} className="mx-1">{skill}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Edit Profile Button for Mobile */}
                {isMobile && (
                    <div style={{ marginTop: "20px", textAlign: "center" }} onClick={() => navigate('/edit-profile', { state: { user } })} >
                        <Link style={{ display: "flex", justifyContent: "center", textDecoration: "none", color: "#111B47" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
                                <path d="M14.3432 9.02L15.2632 9.94L6.2032 19H5.2832V18.08L14.3432 9.02Z" fill="#001137" />
                            </svg>
                            <p style={{ marginLeft: "5px" }}>Edit profile</p>
                        </Link>
                    </div>
                )}
                <div
                    className='container details'
                    style={{
                        width: "100vw",
                        display: "grid",
                        gridTemplateColumns: !isMobile ? "repeat(2, minmax(250px, 1fr))" : "repeat(1, minmax(250px, 1fr))", // Two columns
                        gap: "40px",
                        justifyContent: "center",
                        padding: "20px"
                    }}
                >
                    <div style={{
                        borderRadius: "20px",
                        background: "#FFF",
                        boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                        backdropFilter: "blur(25px)",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "39px 38px"
                    }}>
                        <div style={{ display: 'flex', gap: "20px" }}>
                            <img src={Star} alt="Reputation" />
                            <h5 className='mt-2'>Reputation</h5>
                        </div>
                        <h5>{user.rating_as_guru ? user.rating_as_guru.toFixed(2) : user.rating_as_learner ? user.rating_as_learner.toFixed(2) : 0}</h5>
                    </div>

                    <div style={{
                        borderRadius: "20px",
                        background: "#FFF",
                        boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                        backdropFilter: "blur(25px)",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "39px 38px"
                    }}>
                        <div style={{ display: 'flex', gap: "20px" }}>
                            <img src={Call} alt="Call Duration" />
                            <h5 className='mt-2'>Call Duration</h5>
                        </div>
                        <h5>{user.call_duration_as_guru ? ((user.call_duration_as_guru / 60) / 60).toFixed(2) : user.call_duration_as_learner ? ((user.call_duration_as_learner / 60) / 60).toFixed(2) : "0"}hrs</h5>
                    </div>
                    <div style={{
                        borderRadius: "20px",
                        background: "#FFF",
                        boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                        backdropFilter: "blur(25px)",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "39px 38px"
                    }}>
                        <div style={{ display: 'flex', gap: "20px" }}>
                            <img src={Problems} alt="Call Duration" />
                            <h5 className='mt-2'>Problems</h5>
                        </div>
                        <h5>{user.questions ? user.questions.length : 0}</h5>
                    </div>
                    <div style={{
                        borderRadius: "20px",
                        background: "#FFF",
                        boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                        backdropFilter: "blur(25px)",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "39px 38px"
                    }}>
                        <div style={{ display: 'flex', gap: "20px" }}>
                            <img src={Live} alt="Call Duration" />
                            <h5 className='mt-2'>Live impacts</h5>
                        </div>
                        <h5>0</h5>
                    </div>
                    <div style={{
                        borderRadius: "20px",
                        background: "#FFF",
                        boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                        backdropFilter: "blur(25px)",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "39px 38px"
                    }}>
                        <div style={{ display: 'flex', gap: "20px" }}>
                            <svg className='mt-2' xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M3.19531 20.7929V4C3.19531 3.58366 3.33772 3.2397 3.63637 2.94105C3.93501 2.64241 4.27898 2.5 4.69531 2.5H20.6953C21.1117 2.5 21.4556 2.64241 21.7543 2.94105C22.0529 3.2397 22.1953 3.58366 22.1953 4V16C22.1953 16.4163 22.0529 16.7603 21.7543 17.0589C21.4556 17.3576 21.1117 17.5 20.6953 17.5H6.69531H6.48821L6.34176 17.6464L3.19531 20.7929Z" fill="#79747E" stroke="#79747E" />
                            </svg>
                            <h5 className='mt-2'>Solutions</h5>
                        </div>
                        <h5>{user.answeredList ? user.answeredList.length : 0}</h5>
                    </div>
                    <div style={{
                        borderRadius: "20px",
                        background: "#FFF",
                        boxShadow: "0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)",
                        backdropFilter: "blur(25px)",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "39px 38px"
                    }}>
                        <div style={{ display: 'flex', gap: "20px" }}>
                            <img src={Referral} alt="Call Duration" />
                            <h5 className='mt-2'>Referral impacts</h5>
                        </div>
                        <h5>{0}</h5>
                    </div>

                </div>
                <div className="container">
                    <br />
                    <br />
                    <a href="#" className="navbar-brand">
                        <div className="logosection" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="skillguru">
                                <p style={{
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '25px',
                                    fontWeight: '600',
                                    color: 'linear-gradient(90deg, #C34D1E 0%, #314471 100%)',
                                    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                    margin: '0',
                                    letterSpacing: '0.05em'
                                }}>
                                    Recent Callers
                                </p>
                            </div>
                        </div>
                    </a>
                    <br />
                    <br />

                    <div>
                       
                        <ul style={{listStyle:"none"}}>
                            {recentCallers.length > 0 ? (
                                recentCallers.map((caller, index) => (
                                    <li key={index}>
                                        <div className="border shadow p-3 rounded-3"
                                            style={{
                                                fontSize: '18px',
                                                fontWeight: '500',
                                                display: 'flex',
                                                justifyContent: isMobile ? 'start' : 'space-between',
                                                alignItems: 'center',
                                                flexDirection: isMobile ? 'column' : '',
                                                gap: '30px'
                                            }}>
                                            <span style={{ color: '#000', fontSize: '21px', fontWeight: '700' }}>
                                                {caller.fullName || "Unknown"}
                                            </span>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                                                <span style={{ color:caller["Is online"]? 'rgba(69, 227, 98, 1)':'gray', fontSize: '19px', fontWeight: '500' }}>
                                                    {caller["Is online"] ? "Online" : "Offline"}
                                                </span>
                                                <button className="btn"
                                                    style={{ background:caller["Is online"]? 'rgba(69, 227, 98, 1)':"gray", color: '#fff', fontSize: '19px', fontWeight: '500',cursor:caller["Is online"]?'pointer':'not-allowed' }}
                                                    onClick={() => handleCall(caller)}>
                                                    {!isLoading?"Call Now":"Calling..."}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <p>No  Recent Callers</p>
                            )}
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Profile;
