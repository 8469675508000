import {
    RemoteUser,
    useIsConnected,
    useJoin,
    useRemoteUsers,
} from "agora-rtc-react";
import React from "react";
import { useMediaQuery } from "react-responsive";

export const RemoteUsersVideo = ({ remoteUsers ,remoteScreenId}) => {
    const isMobile = useMediaQuery({ maxWidth: "767px" });

    return (
        <div
            style={{
                width: isMobile ? "100%" : "30%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "40px",
                
            }}
        >
            {remoteUsers.length > 0 ? (
                remoteUsers.map((user) => ( 
                    user.uid !==remoteScreenId &&
                    <div
                        key={user.uid}
                        style={{
                            width: "100%",
                            height: isMobile ? "100%" : "100%",
                            marginTop: isMobile ? "" : "50px",
                        }}
                    >
                        <RemoteUser
                            user={user}
                            style={{ borderRadius: "11px", height: "100%" }}
                        />
                    </div>
                ))
            ) : (
                <p>No remote users detected</p>
            )}
        </div>
    );
};

export default RemoteUsersVideo;
