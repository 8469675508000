import React, { useEffect, useState } from "react";
import Newnavbar from "../components/NewNav/Newnavbar";
import CallFunctionality from "../components/CallFunctionality";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer/Footer";
import PinkCircle from "../components/PinkCircle";
import PurpleCircle from "../components/PurpleCircle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const MainHome = ({ uid, searchTerm }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const [userName, setUserName] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchTerm1, setsearchTerm1] = useState("");
  const [questionText, setQuestionText] = useState("");
  
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingSkills, setLoadingSkills] = useState(true);

  // Fetch Skills Data
  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        const response = await fetch("https://skill-guru-backend.vercel.app/skills-list/skills-list");
        if (!response.ok) throw new Error("Failed to fetch skills");
        const data = await response.json();
        setSkills(data.skillsArray || []);
        console.log("heloooooo")
      } catch (error) {
        console.error("Error fetching skills:", error);
      } finally {
        setLoadingSkills(false);
      }
    };
    fetchSkillsData();
  }, []);

  // Fetch User Data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem("uid");
        if (!userId) {
          console.error("User ID not found in localStorage");
          return;
        }
        const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${userId}`);
        if (!response.ok) throw new Error("Failed to fetch user details");
  
        const data = await response.json();
        setUserName(data.user.fullName || "User");
        console.log(data.user.fullName);
  
        if (!data.user.fullName || !data.user.description || data.user["Guru skills"].length === 0 || data.user["Learner skills"].length === 0) {
          toast.info("Complete your profile ");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoadingUser(false);
      }
    };
  
    fetchUserData();  // Always fetch user data regardless of uid prop
  }, []);
  

  // Handle Inputs
  const handleQuestionChange = (event) => setQuestionText(event.target.value);

  const handleSearch = (event) => {
    const value = event.target.value;
    setsearchTerm1(value);
    if (skills.includes(value) && !selectedSkills.includes(value)) handleSelectSkill(value);
  };

  const handleSelectSkill = (skill) => {
    if (skill && !selectedSkills.includes(skill)) {
      setSelectedSkills((prevSkills) => [...prevSkills, skill]);
      setsearchTerm1("");
    }
  };

  const handleRemoveSkill = (skill) => setSelectedSkills(selectedSkills.filter((s) => s !== skill));

  return (
    <div style={{ maxWidth: "100vw", overflowX:"hidden",height:"105vh" }}>
      

      {/* Call Section */}
      <div className="call" style={{ position: "relative", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
       

        {/* Glassmorphic Card */}
        <div style={{
          marginTop: !isMobile ? '' : "-150px",
          position: "absolute",
          borderRadius: "22px",
          padding: "40px",
          width: !isMobile ? "70vw" : "90vw",
          background: "var(--GLASS, rgba(255, 255, 255, 0.41))",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 2px 3.8px rgba(105, 105, 105, 0.31)",
          height:"auto",
          maxHeight:"100vh",
          overflowY:"inherit"
        }}>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            {/* Skeleton Loader for User Name */}
            <h2 style={{ color: "#313A60", fontWeight: "500", fontSize: !isMobile ? "64px" : "34px" }}>
              Hello, {loadingUser ? <Skeleton width={100} /> : userName}!
            </h2>

            {/* Ask Question */}
            <div style={{ position: "relative", width: "100%", marginTop: "60px" }}>
              <span style={{ position: "absolute", top: "-10px", left: "10px", fontWeight: "400", fontSize: "16px", color: "rgba(73, 69, 79, 1)", background: "#fff", padding: "0 5px" }}>
                Ask Question
              </span>
              <textarea
                placeholder="Type Your Question"
                value={questionText}
                onChange={handleQuestionChange}
                style={{ width: "100%", padding: "18px 15px 95px 15px", border: "1px solid #ccc", borderRadius: "5px", resize: "none", outline: "none" }}
              ></textarea>
            </div>

            {/* Skills Selection with Skeleton Loading */}
            <div className="mt-3" style={{ position: "relative" }}>
              {loadingSkills ? (
                <Skeleton height={40} />
              ) : (
                <input
                  type="text"
                  placeholder="Select a skill/subject..."
                  value={searchTerm1}
                  onChange={handleSearch}
                  className="form-control w-100 p-2"
                />
              )}

              {/* Dropdown for Skills */}
              {searchTerm1 && (
                <ul style={{
                  position: "absolute",
                  width: "100%",
                  background: "#fff",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                  listStyle: "none",
                  padding: "5px",
                  maxHeight: "150px",
                  overflowY: "auto",
                  zIndex: 10,
                }}>
                  {skills
                    .filter((skill) => skill.toLowerCase().includes(searchTerm1.toLowerCase()))
                    .map((skill, index) => (
                      <li key={index} onClick={() => handleSelectSkill(skill)} style={{ padding: "10px", cursor: "pointer", borderBottom: "1px solid #ddd" }}>
                        {skill}
                      </li>
                    ))}
                </ul>
              )}

              {/* Selected Skills */}
              <div style={{ marginTop: "10px", maxHeight: "100px", overflowY: "auto" }}>
                {selectedSkills.map((skill, index) => (
                  <span key={index} style={{ margin: "5px", padding: "5px 10px", background: "#ccc", borderRadius: "5px", display: "inline-block" }}>
                    {skill}
                    <span style={{ cursor: "pointer", marginLeft: "5px", color: "red" }} onClick={() => handleRemoveSkill(skill)}>
                      x
                    </span>
                  </span>
                ))}
              </div>
            </div>

            <div className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CallFunctionality uid={uid} selectedSkills={selectedSkills} question={questionText} />
            </div>
          </div>
        </div>
      </div>

      


    </div>
  );
};

export default MainHome;
