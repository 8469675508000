
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import angleleft from '../assets/angle-left.png';
import profile from '../assets/profile.png';
import { useLocation, useNavigate } from 'react-router-dom';
import Newnavbar from '../components/NewNav/Newnavbar';
import profileImg from '../assets/profile.png'
import { toast } from 'react-toastify';
// import UserInformationForm from './UserInformation/UserInformationForm.jsx';



function EditProfile() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const navigate = useNavigate()
    const location = useLocation();
    const { user } = location.state || {};
    // console.log(user)
    const [skills, setSkills] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [learnerSearch, setLearnerSearch] = useState("");
    const [learnerSkills, setLearnerSkills] = useState([]);
    const [selectedLearnerSkills, setSelectedLearnerSkills] = useState([]);

    
    const [formData, setFormData] = useState({
        fullName: "",
        bioTitle: "",
        bioDescription: "",
        language: "",
        proficiency: "",
        read: false,
        write: false,
        speak: false,
        gender: "",
        referralCode: "",
        description: "",
    });

    //  User data ko formData state mein set karna
    useEffect(() => {
        if (user) {
            setFormData({
                fullName: user.fullName || "",
                bioTitle: user.bioTitle || "",
                bioDescription: user.bioDescription || "",
                language: user.language || "",
                proficiency: user.proficiency || "",
                read: user.read || false,
                write: user.write || false,
                speak: user.speak || false,
                gender: user.gender || "",
                referralCode: user.referralCode || "",
                description: user.description || "",
            });
        }

        const fetchSkillsData = async () => {
            try {
                const response = await fetch("https://skill-guru-backend.vercel.app/skills-list/skills-list");
                if (!response.ok) throw new Error("Failed to fetch skills");
                const data = await response.json();
                setSkills(data.skillsArray || []);
            } catch (error) {
                console.error("Error fetching skills:", error);
            }
        };

        fetchSkillsData();

        const role = localStorage.getItem("role");
        if (role === "Guru") {
            setSelectedSkills(user?.["Guru skills"] || []);
        } else if (role === "Learner") {
            setSelectedLearnerSkills(user?.["Learner skills"] || []);
        }
    }, [user]);

    //  Input handle karne ka function
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    //  Backend par data save karna
    const saveEditedDetails = async () => {
        console.log(formData)
        try {
            const response = await fetch('https://skill-guru-backend.vercel.app/edit-profile/edit-profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: localStorage.getItem('uid'),
                    ...formData,  //  set all form data 
                    selectedSkills,
                    selectedLearnerSkills,
                    roles: user.role
                }),
            });

            const data = await response.json();
            if (response.ok) {
                toast.success('Profile updated successfully');
            } else {
                toast.error('Error updating profile');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        const fetchSkillsData = async () => {
            try {
                const response = await fetch("https://skill-guru-backend.vercel.app/skills-list/skills-list");
                if (!response.ok) throw new Error("Failed to fetch skills");
                const data = await response.json();
                setSkills(data.skillsArray || []);
            } catch (error) {
                console.error("Error fetching skills:", error);
            }
        };
    
        fetchSkillsData();
    
        // Get role from localStorage
        const role = localStorage.getItem("role");
    
        setSelectedSkills(user['Guru skills']||[])
        setSelectedLearnerSkills(user['Learner skills']||[])
    }, [user]);
    
    
    // Filter skills based on search input
    const filteredSkills = skills.filter(skill =>
        skill.toLowerCase().includes(search.toLowerCase())
    );

    // Add skill to selected list
    const addSkill = (skill) => {
        if (!selectedSkills.includes(skill)) {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    // Remove skill from selected list
    const removeSkill = (skill) => {
        setSelectedSkills(selectedSkills.filter(s => s !== skill));
    };
    const filteredLearnerSkills = skills.filter(skill =>
        skill.toLowerCase().includes(learnerSearch.toLowerCase())
    );


    return (
        <>
           
            <div className="container " style={{ marginTop: "120px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', background: 'rgba(255, 255, 255, 1)', height: '106px' }}>
                    <span style={{
                        background: "rgba(0, 17, 55, 1)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: '600', fontSize: isMobile ? '24px' : '36px'
                    }}> <img onClick={() => navigate('/profile')} src={angleleft} alt="" style={{ height: isMobile ? '29px' : '38px', marginRight: isMobile ? '5px' : '10px', cursor: 'pointer' }} /> My Profile</span>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: isMobile ? '140px' : '250px', justifyContent: 'center', marginRight: isMobile ? '30px' : "" }}>
                        <img
                            className="rounded-circle"
                            style={{
                                height: "150px",
                                width: "150px",
                                background: user?.profileImageUrl ? "" : "pink",
                            }}
                            src={user?.profileImageUrl || profileImg}
                            alt=""
                        />

                    </div>
                </div>
                <br />
                {/* <UserInformationForm/> */}
                <div className="border shadow" style={{
                    width: isMobile ? '100%' : '80%',
                    padding: isMobile ? '10px' : '50px',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    maxHeight: '100%',
                    maxWidth: '100vw',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                    <br />
                    <p style={{
                        color: 'rgba(0, 0, 0, 1)',
                        fontWeight: '700',
                        fontSize: '24px',
                        lineHeight: '14px',
                        textAlign: isMobile ? 'center' : ''
                    }}>
                        Your Information
                    </p>

                    {/* Full Name */}
                    <div style={{
                        display: 'flex',
                        gap: isMobile ? '' : isTablet ? '' : '50px',
                        flexDirection: isMobile ? 'column' : isTablet ? 'column' : ''
                    }}>
                        <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                            <span style={{
                                position: 'absolute',
                                top: '-10px',
                                left: '10px',
                                fontWeight: '400',
                                fontSize: '16px',
                                color: 'rgba(73, 69, 79, 1)',
                                background: '#fff',
                                padding: '0 5px'
                            }}>
                                Full Name
                            </span>
                            <input
                                type="text"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    outline: 'none'
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '37px' }}>

                        {/* Bio Title */}
                        <div style={{ position: 'relative', width: '100%', marginTop: '10px' }}>
                            <span style={{
                                position: 'absolute',
                                top: '-10px',
                                left: '10px',
                                fontWeight: '400',
                                fontSize: '16px',
                                color: 'rgba(73, 69, 79, 1)',
                                background: '#fff',
                                padding: '0 5px'
                            }}>
                                Bio Title
                            </span>
                            <input
                                type="text"
                                name="bioTitle"
                                value={formData.bioTitle}
                                onChange={handleChange}
                                // placeholder="Enter Bio Title"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    outline: 'none'
                                }}
                            />
                        </div>

                        {/* Bio Description */}
                        <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                            <span style={{
                                position: 'absolute',
                                top: '-10px',
                                left: '10px',
                                fontWeight: '400',
                                fontSize: '16px',
                                color: 'rgba(73, 69, 79, 1)',
                                background: '#fff',
                                padding: '0 5px'
                            }}>
                                Bio Description
                            </span>
                            <textarea
                                name="bioDescription"
                                value={formData.bioDescription}
                                onChange={handleChange}
                                // placeholder="Enter Bio Description"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    resize: 'none',
                                    outline: 'none'
                                }}
                            />
                        </div>
                    </div>

                    {/* Language Proficiency */}
                    <div style={{ marginTop: '17px' }}>
                        <div style={{
                            display: 'flex',
                            gap: isMobile ? '' : isTablet ? '' : '100px',
                            flexDirection: isMobile ? 'column' : isTablet ? 'column' : ''
                        }}>
                            <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                                <span style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '10px',
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    color: 'rgba(73, 69, 79, 1)',
                                    background: '#fff',
                                    padding: '0 5px'
                                }}>
                                    Language *
                                </span>
                                <input
                                    type="text"
                                    name="language"
                                    value={formData.language}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                    padding: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    resize: 'none',
                                    outline: 'none'
                                    }}
                                />
                            </div>
                            <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>

                                <select
                                    name="proficiency"
                                    value={formData.proficiency}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        resize: 'none',
                                        outline: 'none'
                                    }}
                                    required
                                >
                                    <option value="">Select proficiency</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Proficient">Proficient</option>
                                    <option value="Expert">Expert</option>
                                </select>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '15px',
                            marginTop: '25px',
                            justifyContent: "space-between",
                        }}>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" name="read" checked={formData.read} onChange={handleChange} style={{ width: "20px", height: "20px" }} /> Read
                            </label>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" name="write" checked={formData.write} onChange={handleChange} style={{ width: "20px", height: "20px" }} /> Write
                            </label>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" name="speak" checked={formData.speak} onChange={handleChange} style={{ width: "20px", height: "20px" }} /> Speak
                            </label>
                        </div>
                    </div>

                    {/* Gender Selection */}
                    <div style={{ marginTop: '37px' }}>
                        <span style={{
                            fontWeight: '400',
                            fontSize: '18px',
                            color: 'rgba(73, 69, 79, 1)'
                        }}>
                            Gender
                        </span>
                        <div style={{ display: 'flex', gap: '15px', marginTop: '10px', flexWrap: 'wrap' }}>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Male"
                                    checked={formData.gender === 'Male'}
                                    onChange={handleChange}
                                /> Male
                            </label>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Female"
                                    checked={formData.gender === 'Female'}
                                    onChange={handleChange}
                                /> Female
                            </label>
                            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Other"
                                    checked={formData.gender === 'Other'}
                                    onChange={handleChange}
                                /> Other
                            </label>
                        </div>


                        {/* {referral} */}
                        <div style={{ marginTop: '37px' }}>
                            <span style={{
                                fontWeight: '400',
                                fontSize: '18px',
                                color: 'rgba(73, 69, 79, 1)'
                            }}>
                                Referral Code
                            </span>
                            <div style={{ position: 'relative', width: '100%', marginTop: '10px' }}>
                                <input
                                    type="text"
                                    name="referralCode"
                                    value={formData.referralCode}
                                    onChange={handleChange}
                                    placeholder="Enter Referral Code"
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        outline: 'none'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br/>

                {/* Location */}
                <div className='border shadow ' style={{ width: isMobile ? '100%' : '80%', height: isMobile ? '' : '', padding: isMobile ? '10px' : '50px', borderRadius: '20px' }}>
                    <br />
                    <p style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: '700', fontSize: '24px', lineHeight: '14px', textAlign: isMobile ? 'center' : '' }}>Location</p>
                    <br />
                    <br />
                    <div style={{ display: 'flex', gap: '30px', flexDirection: isMobile ? 'column' : '' }}>
                        <div className="w-100">
                            <div>
                                <span
                                    style={{
                                        background: '#fff',
                                        padding: '4px 8px',
                                        position: 'absolute',
                                        marginTop: '-17px',
                                        marginLeft: '15px',
                                    }}
                                >
                                    <span
                                        style={{
                                            color: 'rgba(73, 69, 79, 1)',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        City
                                    </span>
                                </span>
                            </div>
                            <input
                                type="text"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: 'rgba(73, 69, 79, 1)',
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <span
                                    style={{
                                        background: '#fff',
                                        padding: '4px 8px',
                                        position: 'absolute',
                                        marginTop: '-17px',
                                        marginLeft: '15px',
                                    }}
                                >
                                    <span
                                        style={{
                                            color: 'rgba(73, 69, 79, 1)',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        State
                                    </span>
                                </span>
                            </div>
                            <input
                                type="text"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: 'rgba(73, 69, 79, 1)',
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ position: 'relative', width: isMobile ? '100%' : isTablet ? '100%' : '48.5%', marginTop: '37px' }}>
                        <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                            Phone Number
                        </span>
                        <input
                            style={{
                                width: '100%',
                                padding: '15px',
                                paddingRight: '40px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                resize: 'none',
                                outline: 'none',
                                marginBottom: '20px'
                            }}
                        ></input>
                    </div>
                </div>
                <br />
                <br/>

                {user?.roles?.includes('Learner') && <div className="border shadow" style={{ width: isMobile ? '100%' : '80%', padding: isMobile ? '10px' : '50px', borderRadius: '20px' }}>
                    <p style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: '700', fontSize: '24px', textAlign: isMobile ? 'center' : '' }}>Guru Skills</p>

                    {/* Search Input */}
                    <div style={{ borderBottom: '1px solid #000', flex: 0.7 }}>
                        <div className="border" style={{ display: 'flex', gap: '12px', padding: '12px', borderRadius: '8px' }}>
                            <span><i className="fa-solid fa-magnifying-glass" style={{ fontSize: '18px' }}></i></span>
                            <span style={{ flex: 1 }}>
                                <input
                                    type="text"
                                    style={{ width: '100%', fontSize: '16px', border: 'none', outline: 'none' }}
                                    placeholder="Search for the guru skills"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </span>
                        </div>
                    </div>

                    {/* Filtered Skills List */}
                    {search && (
                        <div className="border shadow" style={{
                            maxHeight: '200px', overflowY: 'auto', padding: '10px', borderRadius: '10px',
                            background: '#f8f9fa', marginTop: '10px'
                        }}>
                            {filteredSkills?.length > 0 ? (
                                filteredSkills.map((skill, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            border: '1px solid #79747e',
                                            borderRadius: '20px',
                                            fontSize: '16px',
                                            padding: '5px 10px',
                                            marginBottom: '5px',
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            marginRight: '5px'
                                        }}
                                        onClick={() => addSkill(skill)}
                                    >
                                        {skill}
                                    </div>
                                ))
                            ) : (
                                <p style={{ fontSize: '14px', color: 'grey' }}>No skills found</p>
                            )}

                        </div>
                    )}

                    {/* Selected Skills */}
                    {selectedSkills.length > 0 && <div className="border shadow" style={{
                        height: 'auto', width: '100%', borderRadius: '10px',
                        background: 'linear-gradient(313.02deg, #E5E6FD 0.94%, #F2F2FE 48.04%, #FFFFFF 97.59%)',
                        display: 'flex', flexWrap: 'wrap', gap: '8px', padding: '10px', marginTop: '20px'
                    }}>
                        {selectedSkills.map((skill, index) => (
                            <div
                                key={index}
                                style={{
                                    border: '1px solid rgba(121, 116, 126, 1)',
                                    borderRadius: '30px',
                                    fontSize: '16px',
                                    padding: '5px 15px',
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    background: '#fff'
                                }}
                                onClick={() => removeSkill(skill)}
                            >
                                {skill} <span style={{ color: 'red', marginLeft: '5px' }}>✖</span>
                            </div>
                        ))}
                    </div>}
                </div>}
                {user?.roles?.includes('Guru') && <div className="border shadow" style={{ width: isMobile ? '100%' : '80%', padding: isMobile ? '10px' : '50px', borderRadius: '20px' }}>
                    <p style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: '700', fontSize: '24px', textAlign: isMobile ? 'center' : '' }}>Learner Skills</p>

                    <div style={{ borderBottom: '1px solid #000', flex: 0.7 }}>
                        <div className="border" style={{ display: 'flex', gap: '12px', padding: '12px', borderRadius: '8px' }}>
                            <span><i className="fa-solid fa-magnifying-glass" style={{ fontSize: '18px' }}></i></span>
                            <span style={{ flex: 1 }}>
                                <input
                                    type="text"
                                    style={{ width: '100%', fontSize: '16px', border: 'none', outline: 'none' }}
                                    placeholder="Search for learner skills"
                                    value={learnerSearch}
                                    onChange={(e) => setLearnerSearch(e.target.value)}
                                />
                            </span>
                        </div>
                    </div>

                    {learnerSearch && (
                        <div className="border shadow" style={{
                            maxHeight: '200px', overflowY: 'auto', padding: '10px', borderRadius: '10px',
                            background: '#f8f9fa', marginTop: '10px'
                        }}>
                            {filteredLearnerSkills.length > 0 ? (
                                filteredLearnerSkills.map((skill, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            border: '1px solid #79747e',
                                            borderRadius: '20px',
                                            fontSize: '16px',
                                            padding: '5px 10px',
                                            marginBottom: '5px',
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            marginRight: '5px'
                                        }}
                                        onClick={() => {
                                            if (!selectedLearnerSkills.includes(skill)) {
                                                setSelectedLearnerSkills([...selectedLearnerSkills, skill]);
                                            }
                                        }}
                                    >
                                        {skill}
                                    </div>
                                ))
                            ) : (
                                <p style={{ fontSize: '14px', color: 'grey' }}>No skills found</p>
                            )}
                        </div>
                    )}

                    {selectedLearnerSkills.length > 0 && (
                        <div className="border shadow" style={{
                            height: 'auto', width: '100%', borderRadius: '10px',
                            background: 'linear-gradient(313.02deg, #E5E6FD 0.94%, #F2F2FE 48.04%, #FFFFFF 97.59%)',
                            display: 'flex', flexWrap: 'wrap', gap: '8px', padding: '10px', marginTop: '20px'
                        }}>
                            {selectedLearnerSkills.map((skill, index) => (
                                <div
                                    key={index}
                                    style={{
                                        border: '1px solid rgba(121, 116, 126, 1)',
                                        borderRadius: '30px',
                                        fontSize: '16px',
                                        padding: '5px 15px',
                                        display: 'inline-block',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        background: '#fff'
                                    }}
                                    onClick={() => setSelectedLearnerSkills(selectedLearnerSkills.filter(s => s !== skill))}
                                >
                                    {skill} <span style={{ color: 'red', marginLeft: '5px' }}>✖</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>}
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'end', marginTop: '30px', width: "67vw" }} onClick={() => saveEditedDetails()}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <div style={{ background: 'rgba(72, 94, 146, 1)', gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', width: isMobile ? '100px' : '150px', justifyContent: 'center' }}>
                            <span style={{ fontWeight: '500', fontSize: isMobile ? '13px' : '24px', color: 'rgba(255, 255, 255, 1)' }}>Save</span>
                        </div>
                    </div>
                </div>
                <br/>
                <br />
            </div>
        </>
    )
}

export default EditProfile