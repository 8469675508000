import React, { useEffect, useState } from 'react';
import '../Back_to_top/Back_to_top.css';

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <a href='http://wa.me/919560695530' target='_blank'>
                <button
                    className={`back-to-top ${isVisible ? 'show' : ''}`}
                    onClick={scrollToTop}
                    style={{ display: isVisible ? 'block' : 'none' }}
                >
                    <span><i class="fa-brands fa-square-whatsapp text-success " style={{ fontSize: "54px" }}></i>

                    </span>
                </button>
            </a>

        </>
    );
};

export default BackToTop;