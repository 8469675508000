import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import team from '../Team/team.png';
import chetansir from '../Team/Chetan sir.jpeg';
import priyanka from '../Team/Priyanka Vishal.jpeg';
import mahajbeen from '../Team/Mahjabeen.jpeg';
import Chinmoy from "../Team/Chinmoy Roy.jpeg"
import niteen from '../Team/niteen.jpeg';
import rahul from '../Team/Rahul gangwar.jpg';
import priya from '../Team/Priya shukla.jpeg';
import abdul from '../Team/Abdul img.jpg';
import Advisor from '../Team/WhatsApp Image 2025-03-24 at 18.36.03_1f1573ab.jpg'
import Footer from '../../components/Footer/Footer';


function Team() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const allTeams = [
        {
            img: chetansir,
            name: 'Chetan Gupta',
            specialty: 'Strategic Advisor',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/chetan-gupta-612bb842/' }]
        },
        {
            img: priyanka,
            name: 'Priyanka Vishal',
            specialty: 'Chief Advisor',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/priyanka-vishal-94218aa3/' }]
        },
        {
            img: Chinmoy,
            name: 'Chinmoy Roy',
            specialty: 'Product Designer',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/superchinmoyroy/?originalSubdomain=in' }]
        },
        {
            img: Advisor,
            name: 'CA Dr. Rajkumar S Adukia',
            specialty: 'Advisor',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/carajkumaradukia/?originalSubdomain=in' }]
        },
        
        {
            img: niteen,
            name: 'Niteen Pandey',
            specialty: 'Frontend Developer',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/niteen-pandey-40460a169/' }]
        },
        {
            img: rahul,
            name: 'Rahul Gangwar',
            specialty: 'Backend Developer',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/rahul-gangwar-a44697294/' }]
        },
        {
            img: priya,
            name: 'Priya Shukla',
            specialty: 'Frontend Developer',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/priya-shukla-b0a943280/' }]
        },
        {
            img: abdul,
            name: 'Abdullah Ahmad',
            specialty: 'Frontend Developer',
            social: [{ platform: 'linkedin', link: 'https://www.linkedin.com/in/abdullah-ahmad-9a99512bb/' }]
        },
        
    ];


    const [visibleTeams, setVisibleTeams] = useState(10);
    return (
        <>
            {/* img section */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)', height: isMobile ? '100%' : '' }}>
                <div className="container">
                    {/* img section */}
                    <div className="row featurette " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-md-7">
                            <h1 className="featurette-heading" style={{ textAlign: isMobile ? 'center' : '' }}>
                                <span style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '700', fontSize: isMobile ? '34px' : '54px', letterSpacing: '-1px' }}>Meet Our  </span>
                                <span style={{
                                    background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    fontWeight: '700', fontSize: isMobile ? '34px' : '54px'
                                }}>Team</span>
                            </h1>
                            <p className="lead" style={{ color: 'rgba(95, 105, 128, 1)', fontSize: isMobile ? '15px' : '24px', fontWeight: '500', textAlign: isMobile ? 'center' : '' }}>Connect with Our Team : Experts Ready to Assist You</p>
                        </div>
                        <div className="col-md-5" style={{ display: 'flex', alignItems: 'baseline', textAlign: isMobile ? 'center' : 'end' }}>
                            <img src={team} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" style={{ width: isMobile ? '260px' : '460px', }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Team section */}
            <div className="container mt-5">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-4">
                    {allTeams.slice(0, visibleTeams).map((team, index) => (
                        <div className="col" key={index} style={{ borderRadius: '20px' }}>
                            <div className="card shadow">
                                <div style={{ background: 'rgba(234, 239, 255, 1)', display: 'flex', justifyContent: 'center' }} className="bd-placeholder-img card-img-top">
                                    <img src={team.img} alt={team.name} style={{ height: '300px', width: '100%', objectFit: 'cover' }} />
                                </div>
                                <div className="card-body">
                                    <div className="mt-2">
                                        <h1 style={{ color: 'rgba(0, 17, 55, 1)', fontWeight: '700', fontSize: '18px' }}>{team.name}</h1>
                                        <p style={{ color: 'rgba(75, 85, 99, 1)', fontWeight: '500', fontSize: '14px' }}>{team.specialty}</p>
                                        <div className="d-flex gap-2">
                                            {team.social.map((social, idx) => (
                                                <a key={idx} href={social.link} target="_blank" rel="noopener noreferrer" className="border shadow-lg"
                                                    style={{ borderRadius: '50%', height: '50px', width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', textDecoration: 'none' }}>
                                                    <i className={`fa-brands fs-4 fa-${social.platform}`} style={{ color: 'blue' }}></i>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Team;