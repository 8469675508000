import React from "react";
import { useNavigate } from "react-router-dom";

const Message = ({ payload }) => {
  const navigate = useNavigate();

  const handleAccept = async() => {
    console.log(payload.body)
    const channelName = payload.data.channelName
    const token=payload.data.agoraToken
    const callerId = payload.data.callerId
    console.log("hellloooo",payload.data.callerId)
    console.log(channelName,token)
    await storeCallDetails(payload.data.callerId);
    const role = 'guru';
    navigate("/video-call", { state: { channelName,token,role,callerId} }); // Navigate to the video call screen
    
  };


    const storeCallDetails = async (callerId) => {
    const uid = localStorage.getItem('uid');
    
    try {
      const response = await fetch("https://skill-guru-backend.vercel.app/call-details/call-details", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({callerId,uid })
      });
      const text = await response.text(); // Get raw response
      console.log("Raw Response:", text);
      
      try {
        const data = JSON.parse(text);
        console.log("Parsed JSON:", data);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
      }
    } catch (error) {
      console.error("Error saving call details:", error);
    }
  };

  return (
    <div>
      <h4>{payload.notification.title}</h4>
      <p>{payload.notification.body}</p>
      <div style={{ display: "flex", gap: "20px" }}>
        <button
          style={{ backgroundColor:  "#45E362",color:"#FFF",padding:"4px 8px",border:"none",outline:"none",borderRadius:"5px",cursor: "pointer"}}
          onClick={handleAccept}
        >
          Accept
        </button>
        <button style={{ backgroundColor: "#FF0000", cursor: "pointer",color:"#FFF",padding:"4px 8px",border:"none",outline:"none",borderRadius:"5px" }}>Reject</button>
      </div>
    </div>
  );
};

export default Message;
