import React, { useEffect, useState } from 'react';
import PurpleCircle from '../components/PurpleCircle';
import PinkCircle from '../components/PinkCircle';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const SelectBothSkills = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const [searchTermGuru, setSearchTermGuru] = useState('');
  const [searchTermLearner, setSearchTermLearner] = useState('');
  const [guruSkills, setGuruSkills] = useState([]); // State for Guru Skills
  const [learnerSkills, setLearnerSkills] = useState([]); // State for Learner Skills
  const [isDropdownOpenGuru, setIsDropdownOpenGuru] = useState(false);
  const [isDropdownOpenLearner, setIsDropdownOpenLearner] = useState(false);
  const [items, setItems] = useState(['react', 'javascript', 'c++', 'java']); // Sample skills
  const location = useLocation();
  const { selectedRoles } = location.state || {};
  const role = selectedRoles;
  const [skills, setSkills] = useState([]);





  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        const response = await fetch("https://skill-guru-backend.vercel.app/skills-list/skills-list");
        if (!response.ok) throw new Error("Failed to fetch skills");
        const data = await response.json();
        setSkills(data.skillsArray || []);
        setItems(data.skillsArray || [])
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };
    fetchSkillsData();
  }, []);

  // Filter items based on the search term
  const filteredGuruItems = items.filter((item) =>
    item.toLowerCase().includes(searchTermGuru.toLowerCase())
  );

  const filteredLearnerItems = items.filter((item) =>
    item.toLowerCase().includes(searchTermLearner.toLowerCase())
  );

  // Handle item click for Guru and Learner Skills
  const handleItemClick = (item, isGuru) => {
    if (isGuru) {
      setGuruSkills((prevItems) =>
        prevItems.includes(item) ? prevItems.filter((i) => i !== item) : [...prevItems, item]
      );
     
    } else {
      setLearnerSkills((prevItems) =>
        prevItems.includes(item) ? prevItems.filter((i) => i !== item) : [...prevItems, item]
      );
      
    }
  };

  const handleSubmit = async () => {
    const uid = localStorage.getItem('uid');
    try {
      const response = await fetch("https://skill-guru-backend.vercel.app/skills/store-both-skills", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid, guruSkills, learnerSkills })
      });
      const text = await response.text(); // Get raw response
      console.log("Raw Response:", text);
      navigate('/')
      try {
        const data = JSON.parse(text);
        console.log("Parsed JSON:", data);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
      }
    } catch (error) {
      console.error("Error saving skills:", error);
    }
  };

  const handleNext = async () => {
    console.log('Guru Skills:', guruSkills);
    console.log('Learner Skills:', learnerSkills);
    await handleSubmit()

  };

  return (
    <div style={{ position: 'relative' }} data-scroll-section >
      {/* Background Circles */}
      <div className="signup-circle1">
        <PurpleCircle />
      </div>
      <div className="signup-circle2">
        <PinkCircle />
      </div>

      {/* Select Role Card */}
      <div
        className="select-role-card"
        style={{
          background: 'var(--GLASS, rgba(255, 255, 255, 0.41))',
          backdropFilter: 'blur(50px)',
          boxShadow: '0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)',
          width: !isMobile ? '494px' : '354px',
          padding: '20px',
          position: 'absolute',
          minHeight: !isMobile ? '664px' : '530px',
          zIndex: 2,
          top: !isMobile ? '50%' : '70%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '28.46px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'height 0.3s ease',
        }}
      >
        <div>
          <img
            src="https://s3-alpha-sig.figma.com/img/757c/cc3f/aae7b0df685d3564b13a0210a37ff70e?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=D3LJFKZ1kJ0BS5bFL6nXHxr6-zub4UU5x7pSfFP9A3-BbvxHPGHDIrxpRn3SqlY7J9lXHjVB7Q2fv2oH6CaWcHgkIhMeYTT3nLHBfmXm9IVESyGv4zUeOj~G08Q2KHji3wuYGXG9l5Hs47PmiG0iSdqCh~DIhtvHsM9wvqdFIG5xmMMKAsSZfQG9IuXiPpXoKVJS7Uf6hdaB2xwGhkqNw1KhXz7RbKa7-TIVqvvtOGG1H039Jky1cvig6ZNT9EQN9DLErDhmkU411A1mBiDhZ~7bh2ZqQdZX0~7oR2LjgfBtvWkQ~-LlE3qGhqig-VJwP~twQX1LGiiBMXkAbqTUyw__"
            style={{ width: '45px', height: '64px' }}
            alt="logo"
          />
        </div>
        <div>
          <p className="colored-text" style={{ fontSize: isMobile ? '12px' : '' }}>
            Add your {role} skills
          </p>
        </div>

        {/* Guru Skills Input and Dropdown */}
        <div style={{ position: 'relative', width: '334px' }}>
          <label
            htmlFor="guru-input-field"
            style={{
              position: 'absolute',
              top: '-8px',
              left: '15px',
              background: '#fff',
              padding: '0 5px',
              fontSize: '12px',
              color: 'gray',
              zIndex: 1,
            }}
          >
            Guru Skills
          </label>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              id="guru-input-field"
              value={searchTermGuru}
              placeholder="Search or select..."
              onChange={(e) => setSearchTermGuru(e.target.value)}
              onClick={() => setIsDropdownOpenGuru(!isDropdownOpenGuru)}
              style={{
                width: '100%',
                padding: '10px',
                paddingRight: '30px',
                borderRadius: '5px',
                border: '3px solid rgba(72, 94, 146, 1)',
              }}
            />
            <button
              onClick={() => setIsDropdownOpenGuru(!isDropdownOpenGuru)}
              style={{
                position: 'absolute',
                right: '10px',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
                color: 'rgba(72, 94, 146, 1)',
              }}
            >
              {isDropdownOpenGuru ? '▲' : '▼'}
            </button>
          </div>
        </div>

        {/* Dropdown Menu for Guru Skills */}
        {isDropdownOpenGuru && (
          <div style={{
            marginTop: '10px',
            width: '334px',
            maxHeight: '150px',
            overflowY: 'auto',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            zIndex: 1000,
            scrollbarWidth: 'thin',
            padding: '5px',
          }}>
            {filteredGuruItems.length > 0 ? (
              filteredGuruItems.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleItemClick(item, true)}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #f0f0f0',
                    backgroundColor: guruSkills.includes(item) ? 'rgba(72, 94, 146, 1)' : '#fff',
                    borderRadius: '7px',
                    margin: '3px',
                    color: guruSkills.includes(item) ? 'white' : 'black',
                  }}
                >
                  {item}
                </div>
              ))
            ) : (
              <div style={{ padding: '10px', textAlign: 'center', color: '#999' }}>
                No items found
              </div>
            )}
          </div>
        )}


        {/* Learner Skills Input and Dropdown */}
        <div style={{ position: 'relative', width: '334px', marginTop: '20px' }}>
          <label
            htmlFor="learner-input-field"
            style={{
              position: 'absolute',
              top: '-8px',
              left: '15px',
              background: '#fff',
              padding: '0 5px',
              fontSize: '12px',
              color: 'gray',
              zIndex: 1,
            }}
          >
            Learner Skills
          </label>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              id="learner-input-field"
              value={searchTermLearner}
              placeholder="Search or select..."
              onChange={(e) => setSearchTermLearner(e.target.value)}
              onClick={() => setIsDropdownOpenLearner(!isDropdownOpenLearner)}
              style={{
                width: '100%',
                padding: '10px',
                paddingRight: '30px',
                borderRadius: '5px',
                border: '3px solid rgba(72, 94, 146, 1)',
              }}
            />

            <button
              onClick={() => setIsDropdownOpenLearner(!isDropdownOpenLearner)}
              style={{
                position: 'absolute',
                right: '10px',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
                color: 'rgba(72, 94, 146, 1)',
              }}
            >
              {isDropdownOpenLearner ? '▲' : '▼'}
            </button>
          </div>
        </div>

        {/* Dropdown Menu for Learner Skills */}
        {isDropdownOpenLearner && (
          <div style={{
            marginTop: '10px',
            width: '334px',
            maxHeight: '150px',
            overflowY: 'auto',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            zIndex: 1000,
            scrollbarWidth: 'thin',
            padding: '5px',
          }}>
            {filteredLearnerItems.length > 0 ? (
              filteredLearnerItems.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleItemClick(item, false)}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #f0f0f0',
                    backgroundColor: learnerSkills.includes(item) ? 'rgba(72, 94, 146, 1)' : '#fff',
                    borderRadius: '7px',
                    margin: '3px',
                    color: learnerSkills.includes(item) ? 'white' : 'black',
                  }}
                >
                  {item}
                </div>
              ))
            ) : (
              <div style={{ padding: '10px', textAlign: 'center', color: '#999' }}>
                No items found
              </div>
            )}
          </div>
        )}


        <button
          onClick={handleNext}
          style={{
            width: '334px',
            height: '40px',
            background: 'rgba(72, 94, 146, 1)',
            borderRadius: '10px',
            color: 'white',
            marginTop: '20px',
            cursor: "pointer"
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectBothSkills;
