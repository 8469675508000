import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "locomotive-scroll/dist/locomotive-scroll.css";
import SIgnup from "./screens/SIgnup";
import Home from "./screens/Home/Home";
import Login from "./screens/Login";
import SelectRole from "./screens/SelectRole";
import SelectSkills from "./screens/SelectSkills";
import SelectBothSkills from "./screens/SelectBothSkills";
import MainHome from "./screens/MainHome";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { messaging, requestFCMToken } from "./configs/firebase";
import { onMessage } from "firebase/messaging";
import Message from "./components/Message";
import VideoCall from "./screens/VideoCall";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import Postquestion from "./screens/Postquestion/Postquestion";
import Answer from "./screens/Answers/Answer";
import Postquestion_pop_up from "./screens/Postquetion_popup/Postquestion_pop_up";
import PostAnswer_pop_up from "./screens/PostAnswer_popUp/Postquestion_pop_up";
import Gettoknow from "./screens/Gettoknow/Gettoknow";
import Aboutus from "./screens/Aboutus/Aboutus";
import Profile from "./screens/Profile";
import EditProfile from "./screens/EditProfile";
import BackToTop from "./components/Back_to_top/Back_to_top";
import Privacy from "./screens/Privacy";
import Donate from "./screens/Donate/Donate";
import RejoinScreem from "./screens/RejoinScreem";
import Footer from "./components/Footer/Footer";
import Newnavbar from "./components/NewNav/Newnavbar";
import Navbar from "./components/NavbarNew/Navbar";
import Contactus from "./screens/ContactUs/ContactUs";
import Team from "./screens/Team/Team";
import FAQ from "./screens/FAQs/FAQs";
import Notifications from "./screens/Notifications";


const App = () => {
  const [uid, setUid] = useState(localStorage.getItem("uid"));

  const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

  useEffect(() => {
    requestFCMToken();
    setUid(localStorage.getItem("uid"));

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Notification received: ", payload);
      toast.info(<Message payload={payload} />, {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
    <Router>
      <ToastContainer />
      <LocationBasedNavbar uid={uid} setUid={setUid} />
      <Routes>
        <Route path="/signup" element={<SIgnup setUid={setUid} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/select-role" element={<SelectRole />} />
        <Route path="/select-skills" element={<SelectSkills />} />
        <Route path="/select-both-skills" element={<SelectBothSkills />} />
        <Route path="/questions" element={<Postquestion />} />
        <Route path="/answers" element={<Answer />} />
        <Route path="/post-question" element={<Postquestion_pop_up />} />
        <Route path="/post-answer" element={<PostAnswer_pop_up />} />
        <Route path="/community" element={<Gettoknow />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/rejoin" element={<RejoinScreem uid={uid} />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/team" element={<Team />} />
        <Route path="/faqs" element={<FAQ/>}/>
        <Route path="/notifications" element = {<Notifications  uid={uid}/>}/>
        <Route path={"/"} element={<Home />} />
        <Route
          path="/video-call"
          element={
            <AgoraRTCProvider client={client}>
              <VideoCall />
            </AgoraRTCProvider>
          }
        />
      </Routes>
      
    </Router>
    <BackToTop />
    </>
    
  );
};

// Separate component to use useLocation inside Router
const LocationBasedNavbar = ({ uid, setUid }) => {
  const location = useLocation();
  const isVideoCallPage = location.pathname === "/video-call";

  return !isVideoCallPage ? (!uid ? <Navbar setUid={setUid} /> : <Newnavbar setUid={setUid} />) : null;
};

export default App;