import React, { useState } from "react";
import "../FAQs/FAQs.css";
import { useMediaQuery } from "react-responsive";
import faqimg from "../FAQs/faq.png"
import Footer from "../../components/Footer/Footer";

const FAQ = () => {
  const [activeSection, setActiveSection] = useState("learner");
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  // Learners
  const faqs = [
    { question: "1️⃣ How can I start learning?", answer: "Just sign up, choose your subject or skill, and connect with a Guru for a one-on-one session." },
    { question: "2️⃣ Is learning free or paid?", answer: "Most learning sessions are free, but some experts (optional) may charge a small fee." },
    { question: "3️⃣ Can I choose my own Guru?", answer: "Yes! You can browse Gurus, check their skills, and choose the one who fits your needs." },
    { question: "4️⃣ How do I connect with a Guru?", answer: "You can chat, call, or video call Gurus instantly from your account." },
    { question: "5️⃣ What subjects or skills can I learn?", answer: "You can learn school subjects, coding, marketing, public speaking, and many other skills!" },
    { question: "6️⃣ Can I learn anytime, anywhere?", answer: "Yes! Our platform is available 24/7, so you can learn when it’s convenient for you." },
    { question: "7️⃣ Will I get a certificate?", answer: "Some courses offer certificates, while others focus on practical learning." },
    { question: "8️⃣ What if I have doubts during learning?", answer: "You can message your Guru anytime to ask for help." },
    { question: "9️⃣ Can I switch Gurus if I don’t like one?", answer: "Yes, you can choose a different Guru anytime." },
  ];

  // GURUs
  const faqsa = [
    { questions: "1️⃣ Who can become a Guru?", answers: "Anyone with teaching skills or expertise in a subject can become a Guru." },
    { questions: "2️⃣ How do I become a Guru?", answers: "Sign up, create your profile, and start teaching students who need help." },
    { questions: "3️⃣ Do I need a degree to teach?", answers: "No, but you should have good knowledge of what you’re teaching." },
    { questions: "4️⃣ Can I earn money as a Guru?", answers: "Yes! You can offer free lessons or charge fees for premium sessions." },
    { questions: "5️⃣ How do I connect with learners?", answers: "Students will find you based on your skills and send you a request for learning." },
    { questions: "6️⃣ Can I set my own schedule?", answers: "Yes! You decide when and how often you want to teach." },
    { questions: "7️⃣ What if a learner asks too many questions?", answers: "That’s great! More questions = better learning. You can set limits if needed." },
    { questions: "8️⃣ Can I teach multiple subjects?", answers: "Yes! You can list as many subjects as you are comfortable teaching." },
    { questions: "9️⃣ How do I get paid?", answers: "If you offer paid sessions, you will receive payments directly from learners." },
    { questions: "🔟 Can I stop being a Guru anytime?", answers: "Yes, you can pause or delete your profile anytime." },
  ];
  const isMobile = useMediaQuery({ maxWidth: 767 });


  return (
    <>
    <div className="background-circles">
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="circle three"></div>
      </div>
      {/* img section */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)', height: isMobile ? '100%' : '' }}>
        <div className="container">
          {/* img section */}
          <div className="row featurette " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="col-md-7">
              <h1 className="featurette-heading" style={{ textAlign: isMobile ? 'center' : '' }}>
                <span style={{
                  background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: '700', fontSize: isMobile ? '34px' : '54px'
                }}>FAQs</span>
              </h1>
              <p className="lead" style={{ color: 'rgba(95, 105, 128, 1)', fontSize: isMobile ? '15px' : '24px', fontWeight: '500', textAlign: isMobile ? 'center' : '' }}>Have Questions? Get Answers Today</p>
            </div>
            <div className="col-md-5" style={{ display: 'flex', alignItems: 'baseline', textAlign: isMobile ? 'center' : 'end' }}>
              <img src={faqimg} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" style={{ width: isMobile ? '260px' : '260px', }} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Navbar Section */}
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
           gap:"60px",
            height: "106px",
          }}
        >
          <a
            href="#"
            className="navbar-brand"
            onClick={() => setActiveSection("learner")}
            style={{ cursor: "pointer" }}
          >
            <div className="logosection" style={{ display: "flex", alignItems: "center" }}>
              <div className="skillguru">
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: isMobile ? '22px' : "34px",
                    fontWeight: "700",
                    color: activeSection === "learner" ? "#C34D1E" : "#314471",
                    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    margin: "0",
                   borderBottom:(activeSection === "learner")?"1px solid black":"",
                    letterSpacing: "0.05em",
                  }}
                >
                  For Learners
                </p>
              </div>
            </div>
          </a>
          <a
            href="#"
            className="navbar-brand"
            onClick={() => setActiveSection("guru")}
            style={{ cursor: "pointer" }}
          >
            <div className="logosection" style={{ display: "flex", alignItems: "center" }}>
              <div className="skillguru">
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: isMobile ? '22px' : "34px",
                    fontWeight: "700",
                    color: activeSection === "guru" ? "#C34D1E" : "#314471",
                    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    margin: "0",
                    borderBottom:(activeSection === "guru")?"1px solid black":"",
                    letterSpacing: "0.05em",
                  }}
                >
                  For Gurus
                </p>
              </div>
            </div>
          </a>
        </div>

        {/* Learner FAQ Section */}
        {activeSection === "learner" && (
          <section className="faq-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="faq">
                    {faqs.map((faq, index) => (
                      <div className="carda" key={index}>
                        <div
                          className="card-header"
                          onClick={() => toggleFAQ(index)}
                          aria-expanded={openIndex === index}
                        >
                          <h4
                            className="faq-title"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            {faq.question}
                            <i
                              className="fa-solid fa-chevron-up"
                              style={{
                                transform: openIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease-in-out",
                              }}
                            ></i>
                          </h4>
                        </div>
                        <div
                          className="faq-content"
                          style={{
                            maxHeight: openIndex === index ? "200px" : "0",
                            overflow: "hidden",
                            transition: "max-height 0.4s ease-in-out",
                          }}
                        >
                          <div className="card-body">
                            <p
                              className="p-2"
                              style={{
                                color: "rgba(104, 104, 104, 1)",
                                fontSize: "20px",
                                fontWeight: "500",
                              }}
                            >
                              {faq.answer}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Guru FAQ Section */}
        {activeSection === "guru" && (
          <section className="faq-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="faq">
                    {faqsa.map((faqa, index) => (
                      <div className="carda" key={index}>
                        <div
                          className="card-header"
                          onClick={() => toggleFAQ(index)}
                          aria-expanded={openIndex === index}
                        >
                          <h4
                            className="faq-title"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {faqa.questions}
                            <i
                              className="fa-solid fa-chevron-up"
                              style={{
                                transform: openIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease-in-out",
                              }}
                            ></i>
                          </h4>
                        </div>
                        <div
                          className="faq-content"
                          style={{
                            maxHeight: openIndex === index ? "200px" : "0",
                            overflow: "hidden",
                            transition: "max-height 0.4s ease-in-out",
                          }}
                        >
                          <div className="card-body">
                            <p
                              className="p-2"
                              style={{
                                color: "rgba(104, 104, 104, 1)",
                                fontSize: "20px",
                                fontWeight: "500",
                              }}
                            >
                              {faqa.answers}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className='p-2'>
        <div className="container border shadow mt-5" style={{ display: 'flex', flexDirection: 'column', height: isMobile ? '100%' : '', borderRadius: '30px', background: "var(--GLASS, #FFFFFF69)" }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
            <span style={{
              background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: '700', fontSize: isMobile ? '28px' : '40px',
              paddingTop: '37px',
              textAlign: 'center',

            }}>Learners and Gurus</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', textAlign: '', marginTop: '27px', paddingBottom: isMobile ? '40px' : '20px' }}>
            <span   style={{ fontSize: isMobile?"18px":'24px', fontWeight: '600',padding:'14px',textAlign:isMobile?"center":"" }}>Our platform offers a seamless learning experience for both <b style={{ color: 'rgba(49, 68, 113, 1)' }}>Learners</b> and <b style={{ color: 'rgba(49, 68, 113, 1)' }}>Gurus</b>. Learners can explore a variety of courses, join live sessions, and interact with experienced mentors to enhance their skills. They have the flexibility to choose between free and paid courses, request personalized sessions, and seek follow-up support for doubts. Booking a session is simple, and learners can provide feedback to help others make informed choices.
              <br />
              <br />
              For <b style={{ color: 'rgba(49, 68, 113, 1)' }}>Gurus</b>, the platform provides an opportunity to share their knowledge, set their own pricing, and schedule sessions at their convenience. They can conduct live classes, offer mentorship, and interact with <b style={{ color: 'rgba(49, 68, 113, 1)' }}>Learners</b> through various communication channels. Payments are processed securely, and gurus can regularly update their course content. The platform also has a structured cancellation policy to ensure smooth interactions between learners and mentors.</span>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default FAQ;