import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";


const CallFunctionality = ({ uid, selectedSkills, question }) => {
  const [users, setUsers] = useState([]);
  const [selectedGuru, setSelectedGuru] = useState(null); // ✅ Track selected Guru
  const [availableGurus, setAvailableGurus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [currentUserName,setCurrentUserName] = useState("unknown")
  const navigate = useNavigate();
  const userId = localStorage.getItem("uid");



  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${userId}`);
            if (!response.ok) {
                throw new Error("Failed to fetch user details");
            }
            const data = await response.json();
            console.log("current user name",data.user.fullName)
            setCurrentUserName(data.user.fullName)
        } catch (error) {   
            console.error("Error fetching user details:", error);
        }
    };

    if (userId) {
        fetchUserData();
    }
}, [uid]);
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await fetch("https://skill-guru-backend.vercel.app/users/users");
        if (!response.ok) throw new Error("Failed to fetch users");

        const data = await response.json();
        const gurus = data.users.filter(
          (user) =>
            user?.roles?.includes("Guru") &&
            Array.isArray(user?.["Guru skills"]) &&
            user?.["Guru skills"]?.includes(selectedSkills[0]) &&
            user?.id !== uid &&
            user?.fcmToken
        );

        const shuffledGurus = gurus.sort(() => 0.5 - Math.random()).slice(0, 3);
        setAvailableGurus(shuffledGurus);
        if (shuffledGurus.length > 0) {
          setSelectedGuru(shuffledGurus[0]);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersData();
  }, [uid, selectedSkills]);

  const fetchToken = async (channelName) => {
    try {
      const response = await fetch(
        `https://skillgurubackend-1.vercel.app/generate-token?channelName=${channelName}`
      );
      if (!response.ok) throw new Error("Failed to fetch token");

      const data = await response.json();

      return data.token;
    } catch (error) {
      console.error("Error fetching token:", error);
      toast.error("Failed to fetch token.");
      return null;
    }
  };

  // Twilio credentials (replace with your actual credentials)


  
  const handleStoreCallLink = async (uid, link, name) => {

    if (!uid) return;
    if (!name) {
      name = "unknown"
    }

    try {
      const response = await fetch('https://skill-guru-backend.vercel.app/store-call-link/store-call-link', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, link, name }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to store link! ${errorText}`);
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error('Error details:', error);
      alert(error.message);
    }
  };





  const handleCall = async () => {
    if (!selectedGuru) {
      toast.warn("Please select a Guru to call.");
      return;
    }

    setLoading(true);
    const selectedSkill = selectedSkills[0];
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generates a random 4-digit number
    const channelName = `channel_${localStorage.getItem('uid')}_${randomNumber}`;
    console.log(channelName)

    try {
      const token = await fetchToken(channelName);
      if (!token) {
        setLoading(false);
        return;
      }

      const agoraToken = token;
      console.log("Agora Token:", agoraToken);

      // ✅ Store call details
      await fetch("https://skill-guru-backend.vercel.app/call-details/call-details", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ callerId: selectedGuru.id, uid }),
      });

      // ✅ Send notification
      const notificationPayload = {
        token: selectedGuru.fcmToken,
        title: "Incoming Call",
        body: `A student needs help with ${selectedSkill}. Click to join the call.`,
        callerId: userId,
        channelName,
        question,
        agoraToken,
      };

      console.log("Sending notification to:", selectedGuru.fullName);
      console.log("Notification payload:", notificationPayload);

      await fetch("https://skill-guru-backend.vercel.app/notification/send-notification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(notificationPayload),
      });

      toast.success(`Call notification sent to ${selectedGuru.fullName}!`);
      const secretKey = selectedGuru.id; // Must be shared securely

      function encryptData(data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
      }

      const encryptedData = encryptData({ token: selectedGuru.fcmToken, callerId: userId, channelName, question, agoraToken });
      const url = `https://theskillguru.org/video-call?data=${encodeURIComponent(encryptedData)}`;
      handleStoreCallLink(selectedGuru.id, url, currentUserName)
      // sendWhatsAppMessage(9030364766, url)
      console.log(url);
      navigate("/video-call", { state: { channelName, token, question, receiverId: selectedGuru.id } });

    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to send notification.");
    } finally {
      setLoading(false);
    }
  };



  const handleGuruSelect = (guru) => {
    setSelectedGuru(guru);
    setDropdownOpen(false); // Close dropdown on selection
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
      {availableGurus.length !== 0 ?<h3>Select a Guru to Call</h3>:""}

      {availableGurus.length === 0 ? (
        ""
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          {/* Dropdown Button */}
          <div
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            style={{
              width: "100%",
              padding: "10px",
              fontSize: "1rem",
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{selectedGuru ? selectedGuru.fullName : "Select a Guru"}</span>
            <i class="fa-solid fa-caret-down" style={{
              fontSize: "1rem",
              transition: "transform 0.2s",
              transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}></i>

          </div>

          {/* Dropdown List */}
          {isDropdownOpen && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                width: "100%",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                zIndex: 10,
                marginTop: "5px",

              }}
            >
              {availableGurus.map((guru) => (
                <div
                  className="border shadow"
                  key={guru.id}
                  onClick={() => handleGuruSelect(guru)}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "rgba(72, 94, 146, 1)")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = "#fff")}
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    borderBottom: "1px solid #f0f0f0",

                  }}
                >
                  {guru.fullName}
                </div>
              ))}
            </div>
          )}
        </div>
      )}


      <button
        style={{
          padding: "10px 20px",
          fontSize: "1rem",
          fontWeight: "600",
          letterSpacing: "1.5px",
          color: "#fff",
          backgroundColor: loading ? "#999" : "rgba(72, 94, 146, 1)",
          border: "none",
          borderRadius: "5px",
          cursor: loading ? "not-allowed" : "pointer",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          marginTop: "10px",
        }}
        onClick={handleCall}
        disabled={loading}
      >
        {loading ? "Calling..." : "Talk to Guru"}
      </button>
    </div>
  );
};

export default CallFunctionality;
