import React, { useEffect, useState } from 'react';
import logo from '../NavbarNew/logo.png';
import "../NewNav/Newnavbar.css";
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileLogo from '../../assets/profile.png'

function Newnavbar({ onSearch, setUid }) {

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const uid = localStorage.getItem('uid');
    const [userData, setUserData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [isOnline, setIsOnline] = useState(false);
    const [isOn, setIsOn] = useState(false);
    const location = useLocation();
    const isQuestionsPage = location.pathname === "/questions";
    
    const [notifications,setNotifications] = useState([])


    const handleSearch = (e) => {
        setSearchTerm(e.target.value); // Update search term as user types
        onSearch(e.target.value.trim()); // Immediately update the filtered results
        navigate('/questions')
    };
    const handleStoreIsOnline = async (isOnValue) => {
        const uid = localStorage.getItem("uid");
        if (!uid) return;
        console.log(isOnValue); // Logs the correct value

        try {
            const response = await fetch('https://skill-guru-backend.vercel.app/store-is-online/store-is-online', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid, isOn: isOnValue }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to store IsOnline! ${errorText}`);
            }

            const data = await response.json();
            console.log("Success:", data);
        } catch (error) {
            console.error('Error details:', error);
            alert(error.message);
        }
    };


    const toggleSwitch = () => {
        setIsOn((prevIsOn) => {
            const newIsOn = !prevIsOn;
            handleStoreIsOnline(newIsOn);
            return newIsOn;
        });
    };


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${uid}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch user details");
                }
                const data = await response.json();
                setUserData(data || []); // Default to "User" if name is missing
                console.log("hello", data)
                setIsOnline(data.user['Is online'])
                setIsOn(data.user['Is online'])
                setIsOnline(data.user['Is online'])
                setNotifications(Array.isArray(data.user["call-links"]) ? data.user["call-links"] : []);
                console.log(Array.isArray(data["call-links"]) ? data["call-links"] : [])

                console.log("online", isOn)
            } catch (error) {   
                console.error("Error fetching user details:", error);
            }
        };

        if (uid) {
            fetchUserData();
        }
    }, [uid]);





    return (
        <>
            <nav className="navbar navbar-expand-lg sticky-top border-bottom" data-bs-theme="danger" style={{ background: "var(--GLASS, #FFFFFF99)", }}>
                <div className="container">
                    <a className="navbar-brand d-lg-none d-flex align-items-center" href="#">
                        <img src={logo} height="50" alt="Logo" />
                        <span style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: '30px',
                            fontWeight: '600',
                            color: 'linear-gradient(90deg, #C34D1E 0%, #314471 100%),',
                            textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            margin: '0',
                            letterSpacing: '0.05em'
                        }}>Skill Guru</span>
                    </a>
                    <button className="navbar-toggler border-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasLabel">
                                <a className="navbar-brand d-lg-none d-flex align-items-center" href="#">
                                    <img src={logo} height="40" alt="Logo" />
                                    <span style={{
                                        fontFamily: 'Poppins, sans-serif',
                                        fontSize: '25px',
                                        fontWeight: '600',
                                        color: 'linear-gradient(90deg, #C34D1E 0%, #314471 100%),',
                                        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                        margin: '0',
                                        letterSpacing: '0.05em'
                                    }}>Skill Guru</span>
                                </a>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav flex-grow-1 justify-content-between" style={{ alignItems: isMobile ? 'flex-start' : isTablet ? 'flex-start' : 'center' }}>
                                <li className="nav-item " style={{ display: (isMobile || isTablet) ? "none" : "" }} >
                                    <h5 className="offcanvas-title" id="offcanvasLabel">
                                        <a className="navbar-brand  d-flex align-items-center" href="#">
                                            <img src={logo} height="40" alt="Logo" />
                                            <span style={{
                                                fontFamily: 'Poppins, sans-serif',
                                                fontSize: '25px',
                                                fontWeight: '600',
                                                color: 'linear-gradient(90deg, #C34D1E 0%, #314471 100%),',
                                                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                                margin: '0',
                                                letterSpacing: '0.05em'
                                            }}>Skill Guru</span>
                                        </a>
                                    </h5>

                                </li>
                                {isQuestionsPage ? <li className="nav-item"><a className="nav-link" href="#">
                                    <div className='d-flex align-items-center justify-content-between border' style={{ width: isMobile ? '100%' : isTablet ? '100%' : '495px', background: '#F8F7FF', padding: '4px 20px', borderRadius: '30px' }}>
                                        <li className="nav-item"><a className="nav-link d-flex gap-3" href="#">
                                            <span><i class="fa-solid fa-magnifying-glass"></i></span>
                                            <span><input type="text"
                                                placeholder="Search For Answers"
                                                value={searchTerm}
                                                onChange={handleSearch} // Calls function on every input change
                                                style={{ background: 'transparent', border: 'none', outline: 'none', fontSize: '16px', fontWeight: '500' }} /></span>
                                        </a>
                                        </li>
                                        <li className="nav-item" ><a className="nav-link" href="#" style={{ fontSize: '16px' }}><i class="fa-solid fa-microphone"></i></a></li>
                                    </div>
                                </a>
                                </li> : <div style={{ width: isMobile ? '100%' : isTablet ? '100%' : '495px', padding: '4px 20px', borderRadius: '30px' }}></div>}
                                <li className="nav-item"><a className="nav-link " href="/" style={{ fontSize: '16px', fontWeight: '500' }}>Home</a></li>
                                <li className="nav-item"><a className="nav-link " href="/community" style={{ fontSize: '16px', fontWeight: '500' }}>Community</a></li>
                                <li className="nav-item"><a className="nav-link " href="/questions" style={{ fontSize: '16px', fontWeight: '500' }}>Questions</a></li>
                                <li className="nav-item position-relative">
                                    <a className="nav-link" href="/notifications" style={{ fontSize: "22px", fontWeight: "500" }}>
                                        <i className="fa-solid fa-bell"></i>
                                        {notifications.length > 0 && (
                                            <span
                                                className="position-absolute top-3 start-100 translate-middle badge rounded-pill bg-danger"
                                                style={{ fontSize: "8px", padding: "4px 6px" }}
                                            >
                                                {notifications.length}
                                            </span>
                                        )}
                                    </a>
                                </li>
                                <li><div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <span>{isOn ? "ON" : "OFF"}</span>
                                    <div className={`toggle-switch ${isOn ? "on" : ""}`} onClick={toggleSwitch}>
                                        <div className="switch-handle"></div>
                                    </div>
                                </div></li>
                                <li className="nav-item">
                                    <div className="dropdown">
                                        <a href="#" className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img
                                                src={userData?.user?.profileImageUrl || ProfileLogo}
                                                alt="User"
                                                width="32"
                                                height="32"
                                                className="rounded-circle"
                                            />

                                        </a>

                                        <ul className="dropdown-menu text-small shadow">
                                            <li><a className="dropdown-item" href="/profile">Profile</a></li>
                                            {/* <li><a className="dropdown-item" href="#">Settings</a></li> */}
                                            <li><a className="dropdown-item" href="/about-us">About us</a></li>
                                            {/* <li><hr className="dropdown-divider" /></li> */}
                                            <li onClick={() => {
                                                localStorage.removeItem('uid');
                                                navigate('/signup');
                                                setUid(false)
                                            }} >
                                                <a className="dropdown-item" href="#">Sign out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Newnavbar;
