import React from 'react'
import PurpleCircle from '../components/PurpleCircle'
import PinkCircle from '../components/PinkCircle'
import SignupCard from '../components/SignupCard'
import Navbar from '../components/NavbarNew/Navbar';

const SIgnup = ({ setUid }) => {
  sessionStorage.setItem("hasReloaded", "false");
  return (
    <div style={{ height: "50vh", display: "flex", alignItems: "center" }}>
      <div className="background-circles" style={{}}>
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="circle-small" ></div>
      </div>




      <div >
        <SignupCard setUid={setUid} />
      </div>

    </div>

  )
}

export default SIgnup