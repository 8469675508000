import React, { useEffect, useState, useRef, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import leftimg from "../Answers/left_img.png";
import icon from "../Answers/icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import Newnavbar from "../../components/NewNav/Newnavbar";

function Answer() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const location = useLocation();
  const { item, users } = location.state || {};
  const [selectedImage, setSelectedImage] = useState(null);
  const [visibleAnswers, setVisibleAnswers] = useState(5); // Start with 5 answers
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  console.log("Debug:", item, users);

  // Load more answers when user scrolls down
  const lastAnswerRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && visibleAnswers < item?.answers?.length) {
          setLoading(true);
          setTimeout(() => {
            setVisibleAnswers((prev) => prev + 5); // Load next 5 answers
            setLoading(false);
          }, 1000);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, visibleAnswers, item?.answers]
  );

  return (
    <>
      <div className="container" style={{  }}>
        <img
          onClick={() => navigate("/questions")}
          src={leftimg}
          className="mb-5"
          style={{ height: "35px", cursor: "pointer" }}
          alt=""
        />

        <div
          className="p-4 p-md-5 mb-4 border shadow"
          style={{ background: "rgba(255, 255, 255, 1)", borderRadius: "20px" }}
        >
          <div className="col-lg-12">
            <div
              className="profile"
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "40px" }}
            >
              <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
                <img
                  src={users[item.user_id]?.profileImageUrl}
                  alt=""
                  style={{
                    height: isMobile ? "60px" : "70px",
                    width: "70px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
                <p style={{ fontSize: isMobile ? "20px" : "24px", fontWeight: "500" }}>
                  {users[item.user_id]?.fullName || "Unknown"}
                </p>
              </div>
              <div>
                <img src={icon} alt="" style={{ height: isMobile ? "36px" : "40px", cursor: "pointer" }} />
              </div>
            </div>

            <p style={{ fontSize: isMobile ? "24px" : "32px", fontWeight: "500", lineHeight: "40px" }}>{item.question}</p>
            <div>
              {item.skills.map((skill, index) => (
                <span key={index}>{skill}</span>
              ))}
              
            </div>


            {/* Clickable Question Image */}
            {item.image_url && (
              <img
                src={item.image_url}
                style={{ height: "150px", cursor: "pointer" }}
                onClick={() => setSelectedImage(item.image_url)}
                alt="Question"
              />
            )}

            {/* ✅ Answer Button */}
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div
                onClick={() => navigate("/post-answer", { state: { item } })}
                style={{
                  background: "rgba(72, 94, 146, 1)",
                  gap: "8px",
                  height: isMobile ? "42px" : "64px",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  width: isMobile ? "100px" : "150px",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontWeight: "500", fontSize: isMobile ? "13px" : "24px", color: "rgba(255, 255, 255, 1)" }}>
                  Answer
                </span>
              </div>
            </div>

            {/* ✅ Answers Section */}
            <p style={{ fontSize: isMobile ? "20px" : "32px", fontWeight: "400" }}>Answers</p>
            <hr />
            <br />

            {item?.answers?.slice(0, visibleAnswers).map((a, ansIndex) => (
              <div key={ansIndex} ref={ansIndex === visibleAnswers - 1 ? lastAnswerRef : null}>
                <div
                  className="profile"
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "40px" }}
                >
                  <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
                    <img
                      src={users[a.userId]?.profileImageUrl}
                      alt=""
                      style={{
                        height: isMobile ? "60px" : "70px",
                        width: "70px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                    <p style={{ fontSize: isMobile ? "20px" : "24px", fontWeight: "500" }}>
                      {users[a.userId]?.fullName || "Unknown"}
                    </p>
                  </div>
                </div>

                <p style={{ width: isMobile ? "100%" : "758px", fontSize: isMobile ? "16px" : "20px", fontWeight: "400" }}>
                  {a.text || ""}
                </p>

                {/* Answer Images with Click Event */}
                <div>
                  {a.images?.map((img, imgIndex) => (
                    <img
                      key={imgIndex}
                      className="p-2 mx-0"
                      src={img}
                      alt="Uploaded"
                      style={{ height: "150px", cursor: "pointer" }}
                      onClick={() => setSelectedImage(img)}
                    />
                  ))}
                </div>

                <hr />
              </div>
            ))}

            {/* Lazy Loading Spinner */}
            {loading && <p>Loading more answers...</p>}
          </div>
        </div>
      </div>

      {/* ✅ Full Image View Modal */}
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={() => setSelectedImage(null)}
        >
          <img src={selectedImage} alt="Full Size" style={{ maxWidth: "90%", maxHeight: "70%", borderRadius: "10px" }} />
        </div>
      )}
    </>
  );
}

export default Answer;
