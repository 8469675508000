import React from 'react';
import contact from "../ContactUs/contact.png"
import { useMediaQuery } from 'react-responsive';
import "../ContactUs/ContactUs.css"
import Footer from '../../components/Footer/Footer';

function Contactus() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isDesktop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    return (
        <>
            <div className="background-circles">
                <div className="circle one"></div>
                <div className="circle two"></div>
                <div className="circle three"></div>
            </div>
            {/* img section */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)', height: isMobile ? '100%' : '300px' }}>
                <div className="container">
                    {/* img section */}
                    <div className="row featurette " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-md-7">
                            <h1 className="featurette-heading" style={{ textAlign: isMobile ? 'center' : '' }}>
                                <span style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '700', fontSize: isMobile ? '34px' : '54px', letterSpacing: '-1px' }}>Contact </span>
                                <span style={{
                                    background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    fontWeight: '700', fontSize: isMobile ? '34px' : '54px'
                                }}>Us</span>
                            </h1>
                            <p className="lead" style={{ color: 'rgba(95, 105, 128, 1)', fontSize: isMobile ? '15px' : '24px', fontWeight: '500', textAlign: isMobile ? 'center' : '' }}>Get in Touch: Start Your Journey Today</p>
                        </div>
                        <div className="col-md-5" style={{ display: 'flex', alignItems: 'baseline', textAlign: isMobile ? 'center' : 'end' }}>
                            <img src={contact} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" style={{ width: isMobile ? '260px' : '460px', }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Form section */}
            <div className="container mt-5">
                <section id="contact" className="contact section">
                    <br />
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="row g-4 g-lg-5" >
                            <div className="col-lg-5" style={{ background: 'linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)', borderRadius: '20px', color: '#fff' }}>
                                <div className="info-box" data-aos="fade-up" data-aos-delay="200">
                                    {/* <h3 className='fw-bold' style={{ color: 'rgba(49, 58, 96, 1)' }}>Contact Info</h3> */}
                                    <div className="col-md-7">
                                        <h3 className="featurette-heading" style={{ textAlign: isMobile ? 'center' : '' }}>
                                            <span style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '700', fontSize: isMobile ? '' : '', letterSpacing: '-1px' }}>Contact </span>
                                            <span style={{
                                                background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                                fontWeight: '700', fontSize: isMobile ? '' : ''
                                            }}>Info</span>
                                        </h3>
                                    </div>
                                    <p style={{ color: 'rgba(49, 58, 96, 1)', fontSize: '19px', fontWeight: '600' }}>Get in touch with us! Whether you have questions, need assistance, or want to collaborate, we’re here to help. Reach out today!</p>

                                    <div className="info-item" data-aos="fade-up" data-aos-delay="300">
                                        <div className="icon-box">
                                            <i class="fa-solid fa-location-dot fs-4" style={{ color: 'rgba(49, 58, 96, 1)' }}></i>
                                        </div>
                                        <div className="content">
                                            <h4 style={{ color: 'rgba(49, 58, 96, 1)' }}>Our Location</h4>
                                            <p style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '600' }}>Sector 27, Gurugram, Haryana 122009</p>
                                        </div>
                                    </div>

                                    <div className="info-item" data-aos="fade-up" data-aos-delay="400">
                                        <div className="icon-box">
                                            <i class="fa-solid fa-phone" style={{ color: 'rgba(49, 58, 96, 1)' }}></i>
                                        </div>
                                        <div className="content">
                                            <h4 style={{ color: 'rgba(49, 58, 96, 1)' }}>Phone Number</h4>
                                            <p style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '600' }}>+91- 956 0695 530</p>
                                            <p></p>
                                        </div>
                                    </div>

                                    <div className="info-item" data-aos="fade-up" data-aos-delay="500">
                                        <div className="icon-box">
                                            <i class="fa-solid fa-envelope" style={{ color: 'rgba(49, 58, 96, 1)' }}></i>
                                        </div>
                                        <div className="content">
                                            <h4 style={{ color: 'rgba(49, 58, 96, 1)' }}>Email Address</h4>
                                            <p style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '600' }}>care@theskillguru.org</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 ">
                                <div className="contact-form border shadow" data-aos="fade-up" data-aos-delay="300">
                                    <h3 style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '700' }}>Get In Touch</h3>
                                    <p style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '600' }}>Get in touch with us! Whether you have questions, need assistance, or want to collaborate, we’re here to help. Reach out today!</p>

                                    <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                                        <div className="row gy-4">

                                            <div className="col-md-6">
                                                <input type="text" name="name" className="form-control" placeholder="Your Name" required="" />
                                            </div>

                                            <div className="col-md-6 ">
                                                <input type="email" className="form-control" name="email" placeholder="Your Email" required="" />
                                            </div>

                                            <div className="col-12">
                                                <input type="text" className="form-control" name="subject" placeholder="Subject" required="" />
                                            </div>

                                            <div className="col-12">
                                                <textarea className="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                                            </div>

                                            <div className="col-12 text-center">
                                                <button className="btn" style={{ background: 'rgba(49, 58, 96, 1)', fontWeight: '600', color: '#fff' }}>Send Message</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>

                    </div>

                </section>
            </div>
            <br />
            <br />
            {/* Map section */}
            <div className=" mt-4 text-center">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                        className="embed-responsive-item"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1753.7192288731003!2d77.0911244!3d28.4663394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18d0943e8349%3A0xb1c92ef38712826b!2sSector%2027%2C%20Gurugram%2C%20Haryana%20122009!5e0!3m2!1sen!2sin!4v1741795056116!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: "0" }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contactus;