import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import ShareLogo from '../assets/share.png'
import RemoteUsersVideo from '../components/RemoteUsersVideo'
import CryptoJS from "crypto-js";


export const Basics = () => {
  const [calling, setCalling] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [otherUser, setOtherUser] = useState("")
  const [remoteScreenId, setRemoteScreenId] = useState(0)

  // ✅ Retrieve token, channelName, and userId from CallFunctionality
  let { channelName, token, uid, receiverId, callerId } = location.state || {};
  const isConnected = useIsConnected();
  const appId = 'f3c2d6e28d7a4ccdbd047e41842e96f6';
  let channel = channelName;

  const secretKey = localStorage.getItem('uid') || "default_secret_key";

  function decryptData(encryptedData) {
    try {
      if (!secretKey) throw new Error("Secret key is missing!");
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) throw new Error("Decryption failed!");
      return JSON.parse(decryptedText);
    } catch (error) {
      console.error("Decryption Error:", error.message);
      return null;
    }
  }
  
  const params = new URLSearchParams(window.location.search);
  const encryptedData = params.get("data");
  
  if (encryptedData) {
    const decryptedData = decryptData(decodeURIComponent(encryptedData));
  
    if (decryptedData) {
      token = decryptedData.agoraToken;
      channel = decryptedData.channelName;
      callerId = decryptedData.callerId;
  
      console.log("Decrypted Data:", decryptedData);
      console.log(token, channel, callerId);
    } else {
      console.error("Failed to decrypt data.");
    }
  }

  useJoin({ appid: appId, channel: channel, token: token ? token : null }, calling);

  // Local user tracks
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn, {
    AEC: true,
    AGC: true,
    ANS: true,
  });

  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  // Remote users
  const remoteUsers = useRemoteUsers();

  // Screen sharing
  const [screenSharing, setScreenSharing] = useState(false);
  const [screenTrack, setScreenTrack] = useState(null);
  const [screenClient, setScreenClient] = useState(() => AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }));

  const startScreenShare = async () => {
    try {
      const track = await AgoraRTC.createScreenVideoTrack({ encoderConfig: "1080p_1" });
      setScreenTrack(track);

      const newScreenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      setScreenClient(newScreenClient);

      await newScreenClient.join(appId, channel, token || null, null);
      await newScreenClient.publish([track]);
      await setScreenSharing(true)







      console.log("Screen sharing started and published track:", track);



    } catch (error) {
      console.error("Error starting screen sharing:", error);
    }
  };


  const stopScreenShare = async () => {
    try {
      if (screenTrack) {
        await screenClient.unpublish(screenTrack);
        screenTrack.stop();
        screenTrack.close();
        setScreenTrack(null);
      }

      await screenClient.leave();
      await handleStoreIsScreenId(otherUser, 1);
      await handleStoreIsScreenId(localStorage.getItem('uid'), 1);
      await setRemoteScreenId(1)
      setScreenClient(null);
      setScreenSharing(false);
      console.log("Screen sharing stopped");
    } catch (error) {
      console.error("Error stopping screen sharing:", error);
    }
  };

  // ✅ Cleanup when user leaves the call
  const handleLeaveCall = async () => {
    try {
      if (screenSharing) {
        await stopScreenShare();
        await handleStoreIsScreenId(otherUser, 1);
        await handleStoreIsScreenId(localStorage.getItem('uid'), 1);
      }

      navigate('/');
    } catch (error) {
      console.error("Error leaving the call:", error);
    }
  };
  const [activeSpeakers, setActiveSpeakers] = useState(new Set());

  useEffect(() => {
    

  }, []);


  const [userCount, setUserCount] = useState(1); // Start with 1 for the local user

  // Define handleStore outside useEffect
  const handleStore = async (storingId) => {
    console.log("User count:", userCount);
    console.log("Remote Users:", remoteUsers[userCount - 1]);

    if (remoteUsers.length > 0) {
      await handleStoreIsScreenId(storingId, remoteUsers[remoteUsers.length - 1]?.uid);
      await fetchUserData();
    } else {
      console.warn("No remote users available.");
    }
  };
  // useEffect to watch userCount changes
  useEffect(() => {
    if (screenSharing && screenTrack) {
      const checkForRemoteUser = setInterval(() => {
        if (remoteUsers.length > 0) {
          console.log("Remote user detected, storing screenId...");
          handleStore(otherUser);
          handleStore(localStorage.getItem('uid'));
          clearInterval(checkForRemoteUser); // Stop polling once the remote user is found
        }

      }, 1000); // Check every second

      return () => clearInterval(checkForRemoteUser); // Cleanup
    }
  }, [screenSharing]);

  useEffect(() => {
    setUserCount(remoteUsers.length + 1);


    const delayFetch = setTimeout(() => {
      fetchUserData();
    }, 3000); // 3-second delay

    return () => clearTimeout(delayFetch);


  }, [remoteUsers]);

  // Runs when remoteUsers or screenSharing change

  console.log("remoteUser", remoteUsers)
  const handleStoreIsScreenId = async (storingId, screenId) => {
    if (!storingId) {
      console.warn("Storing ID is missing!");
      return;
    }

    console.log("Sending screen ID:", { storingId, screenId });

    try {
      const response = await fetch('https://skill-guru-backend.vercel.app/store-screen-id/store-screen-id', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ storingId, screenId }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to store screen ID! ${errorText}`);
      }

      const data = await response.json();
      console.log("Screen ID stored successfully:", data);

      await fetchUserData()


    } catch (error) {
      console.error("Error storing screen ID:", error);
      alert(error.message);
    }
  };


  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem('uid')
      const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user details");
      }
      const data = await response.json();
      console.log("other user", data.user.current_learner_id !== null ? data.user.current_learner_id : data.user.current_guru_id)

      setOtherUser(data.user.current_learner_id !== null ? data.user.current_learner_id : data.user.current_guru_id)
      await setRemoteScreenId(data.user.screenId !== 1 ? data.user.screenId : 1)
      if (data.user.screenId !== 1) {
        setScreenSharing(true)
        console.log(screenSharing)
        console.log(data.user)
      } else {
        setScreenSharing(false)
      }

      console.log("screenId", data.user.screenId)
      console.log("remoteScreenId", remoteScreenId)

    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };




  return (
    <>
      <div className="room">
        {isConnected ? (
          <div
            className=""
            style={{
              width: "90vw",
              height: "80vh",
              display: "flex",

              gap: "40px",
              justifyContent: "center",
              flexDirection: isMobile ? "column" : "",


            }}
          >
            {(screenSharing) ? (
              <>
                {/* Shared screen (local user) */}
                <div
                  className=""
                  style={{
                    width: !isMobile ? "100%" : "100%",
                    height: !isMobile ? "100%" : "100%",
                    marginLeft: "20px",
                    display: 'flex',
                    flexDirection: isMobile ? "column" : "",
                    gap: "30px"


                  }}
                >
                  {remoteUsers.map((user) => (
                    remoteScreenId == user.uid &&
                    <div
                      key={user.uid}
                      style={{
                        width: "100%",
                        height: !isMobile ? "100%" : "50%",
                        marginTop: !isMobile ? "10px" : ""


                      }}
                    >
                      <RemoteUser user={user} style={{ borderRadius: "11px", height: !isMobile ? "100%" : "100%", width: "100%" }} />

                    </div>

                  ))}

                  <RemoteUsersVideo remoteUsers={remoteUsers} remoteScreenId={remoteScreenId} />

                </div>




              </>
            ) : (
              <  >
                {/* Normal Layout */}
                <div

                  style={{
                    width: isMobile ? "100%" : "50%",
                    height: "100%",
                    marginLeft: isMobile ? "20px" : "140px",
                    marginTop: "10px"

                  }}
                >
                  <LocalUser
                    cameraOn={cameraOn}
                    micOn={micOn}
                    videoTrack={localCameraTrack}

                    style={{ borderRadius: "11px" }}
                  >
                    <span className="user-name">You</span>
                  </LocalUser>
                </div>

                {remoteUsers.map((user) => (
                  <div

                    key={user.uid}
                    style={{
                      width: isMobile ? "100%" : "50%",
                      height: "100%",
                      marginLeft: isMobile ? "20px" : "40px",
                      marginTop: "10px",
                      borderRadius: "11px"
                    }}
                  >
                    <RemoteUser user={user} style={{ borderRadius: "11px" }} />
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className="join-room">
            <h1>Connecting to call...</h1>
          </div>
        )}
      </div>


      {isConnected && (
        <div className="control">
          <div className="left-control">
            <button className="btn border" onClick={() => setMic((a) => !a)}>
              {micOn ? <i class="fa-solid fa-microphone fs-4 p-1"></i> : <i class="fa-solid fa-microphone-slash fs-4 p-1"></i>}

            </button>
            <button style={{ maxWidth: "40px" }} className="btn border" onClick={() => setCamera((a) => !a)}>
              {cameraOn ? <i class="fa-solid fa-video fs-5 p-1"></i> : <i class="fa-solid fa-video-slash fs-5 p-1"></i>}
            </button>
            <button
              className="border"
              onClick={screenSharing && screenTrack ? stopScreenShare : startScreenShare}
              style={{ maxWidth: "40px" }}
            >
              {screenSharing && screenTrack ? <i class="fas fa-screen-share fs-5 p-1"></i> : <img style={{ height: "36px", background: "white", }} src={ShareLogo} />}
            </button>
          </div>
          <button

            className="btn btn-phone bg-danger"
            onClick={handleLeaveCall}
          >
            <i className="i-phone-hangup fs-4" />
          </button>
        </div>
      )}
    </>
  );
};

export default Basics;