import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RejoinScreem = ({ uid }) => {
    const [user, setUser] = useState(null); // ✅ Start with null, not an empty array
    const [loadingUser, setLoadingUser] = useState(true);
    const navigate = useNavigate(); // ✅ Use `useNavigate` correctly

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                console.log("Fetching user data for uid:", uid);
                const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${uid}`);
                if (!response.ok) throw new Error("Failed to fetch user details");

                const data = await response.json();
                setUser(data.user);
                console.log("User data:", data.user);
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoadingUser(false);
            }
        };

        if (uid) fetchUserData();
    }, [uid]);

    if (loadingUser) {
        return <p>Loading...</p>; // ✅ Show loading state
    }

    if (!user || !user.Recent_call_details) {
        return <p>No recent calls found.</p>; // ✅ Handle case where no call details exist
    }

    return (
        <div>
            <button
                onClick={() =>
                    navigate("/video-call", {
                        state: {
                            channelName: user.Recent_call_details.channelName,
                            token: user.Recent_call_details.token,
                            question: user.Recent_call_details.question
                        }
                    })
                }
            >
                Join
            </button>
        </div>
    );
};

export default RejoinScreem;
