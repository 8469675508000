import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const Notifications = ({ uid }) => {
    const isMobile = useMediaQuery({ maxWidth: "767px" });
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${uid}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch user details");
                }
                const data = await response.json();
                
                let callLinks = Array.isArray(data.user["call-links"]) ? data.user["call-links"] : [];

                // Sort by timestamp in descending order and get last 3 notifications
                callLinks = callLinks
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                    .slice(0, 3);

                setNotifications(callLinks);
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        if (uid) {
            fetchUserData();
        }
    }, [uid]);

    return (
        <div style={{ width: "98vw", height: "80vh", display: "flex", justifyContent: "center", marginTop: "40px" }}>
            <ul style={{ listStyle: "none", width:!isMobile? "70%":"100%" }}>
                {notifications.length > 0 ? (
                    notifications.map((caller, index) => (
                        <li key={index}>
                            <div className="border shadow p-3 rounded-3"
                                style={{
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    display: 'flex',
                                    justifyContent: isMobile ? 'start' : 'space-between',
                                    alignItems: 'center',
                                    flexDirection: isMobile ? 'column' : '',
                                    gap: '30px'
                                }}>
                                <span style={{ color: '#000', fontSize: '21px', fontWeight: '700' }}>
                                    {caller.name || "Unknown"}
                                </span>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                                    <a href={caller.link} target="_blank" rel="noopener noreferrer">
                                        <button className="btn"
                                            style={{ background: 'rgba(69, 227, 98, 1)', color: '#fff', fontSize: '19px', fontWeight: '500', cursor: 'pointer' }}
                                        >
                                            {!isLoading ? "join call" : "joining..."}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <p>No Recent Callers</p>
                )}
            </ul>
        </div>
    );
};

export default Notifications;
