import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import GoogleIcon from '../assets/google.png'
import LogoIcon from './NavbarNew/logo.png'
import { useMediaQuery } from 'react-responsive';
import './SignUpCard.css'

const SignupCard = ({setUid}) => {


    const [signUpWithEmail, setSignUpWithEmail] = useState(true)
    const firebaseConfig = {
        apiKey: "AIzaSyD9Sqx9_xDwtosKHlXPD3UilY4fOu-c-yI",
        authDomain: "the-skill-guru---durgesh.firebaseapp.com",
        projectId: "the-skill-guru---durgesh",
        storageBucket: "the-skill-guru---durgesh.appspot.com",
        messagingSenderId: "910363851200",
        appId: "1:910363851200:web:9d21217318ec9316bcf025",
        measurementId: "G-6VFQH0M61L"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const idToken = await result.user.getIdToken(); // Fetch the ID token
    
            // Send the ID token to your backend API
            const response = await fetch('https://skill-guru-backend.vercel.app/auth/google', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idToken }),
            });
    
            // Check for errors
            const data = await response.json(); // Parse response JSON
            if (!response.ok) {
                console.error('Backend Error:', data.error); // Log backend error
                throw new Error(data.error || 'Unknown error from backend');
            }
    
            console.log('Google Sign-In Successful:', data);
    
            // Debugging: Log the message received from the backend
            console.log('Message from backend:', data.message); // Added log for debugging

            // Handle successful login (e.g., navigate or store token)
            localStorage.setItem('uid', data.uid);
            if(data.message === 'User created successfully'){
                console.log("bye")
                navigate('/select-role');
            } else {
                setUid(true)
                navigate('/');
            } 
            
        } catch (error) {
            console.error('Error during Google sign-in:', error.message);
            alert('Google Sign-In failed. Please try again.');
        }
    };
    
    











    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [fullName, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isMobile = useMediaQuery({maxWidth:767})
    const handleSignup = async () => {
        // ... handle login logic here ...
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation
        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address.');
            return; // Exit the function if the email is invalid
        }
        if (password.length < 6) {
            alert('Password should contain atleast six characters.');
            return;
        }
        if(fullName.length===0){
            alert('Enter full name');
            return;
        }
        // ... proceed with login logic ...

        // Make the API call
        try {
            const response = await fetch('https://skill-guru-backend.vercel.app/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password ,fullName}),
            });

            if (!response.ok) {
                const errorText = await response.text(); // Get error response text
                throw new Error(`Login failed! ${errorText}`); // Include error text in the message
            }
            const data = await response.json();
            console.log(data)
            localStorage.setItem('uid', data.user.uid); // Store UID in local storage
            console.log(localStorage.getItem('uid'))
            navigate('/select-role'); // Navigate to home page
        } catch (error) {
            console.error('Error details:', error); // Log the error details
            alert(error.message); // Show error message
        }
    }


    return (
        <section className="py-4 signup" style={{width:!isMobile?"494px":"90vw"}}>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div style={{ maxWidth: "520px" }}>
                        <form className=" py-4 " method="get" action="#">
                            <div className="text-center mb-3 px-3">
                                <img className='mb-4' src={LogoIcon} style={{ height: '100px' }} alt="" />
                                
                                <p className="text-muted fw-bold">
                                Sign up or log in to <span style={{color:'rgba(49, 68, 113, 1)',fontSize:'18px'}}>The Skill Guru Foundation.</span> 
                                </p>
                            </div>
                           
                            <div onClick={signInWithGoogle} className=" d-flex  align-items-center justify-content-center" >
                                <a className="btn  d-block bg-gradient " style={{background:'rgba(72, 94, 146, 1)',color:'#fff',height:!isMobile?"60px":"60px",width:isMobile?"430px":""}} href="#">
                                    <div className='mt-2'>
                                    <i class="fa-brands fa-google mx-2  fs-5 mt-2 " > </i> <span className='fw-bold ' style={{fontSize:isMobile?"18px":"22px"}}>Continue with Google</span>
                                    </div>
                                
                                </a>
                            </div>
                          
                           
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignupCard