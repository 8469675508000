import React from 'react';
import { useMediaQuery } from 'react-responsive';
import aboutimg from '../Aboutus/aboutus_img.png';
import bulb from "../Aboutus/bulb.png";
import mask from "../Aboutus/Mask.png";
import mission from "../Aboutus/mission.png";
import punch from "../Aboutus/punch.png";
import imp1 from "../Aboutus/imp1.png";
import imp2 from "../Aboutus/imp2.png";
import imp3 from "../Aboutus/imp3.png";
import imp4 from "../Aboutus/imp4.png";
import value from "../Aboutus/value.png";
import Footer from '../../components/Footer/Footer';

function Aboutus() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isDesktop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });


    return (
        <>
            <div className="background-circles">
                <div className="circle one"></div>
                <div className="circle two"></div>
                <div className="circle three"></div>
            </div>
            {/* img section */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'linear-gradient(268.78deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)', height: isMobile ? '100%' : '312px' }}>
                <div className="container">
                    {/* img section */}
                    <div className="row featurette " style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-md-7">
                            <h1 className="featurette-heading" style={{ textAlign: isMobile ? 'center' : '' }}>
                                <span style={{ color: 'rgba(49, 58, 96, 1)', fontWeight: '600', fontSize: isMobile ? '34px' : '54px', letterSpacing: '-1px' }}>About </span>
                                <span style={{
                                    background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    fontWeight: '600', fontSize: isMobile ? '34px' : '54px'
                                }}>Us </span>
                            </h1>
                            <p className="lead" style={{ color: 'rgba(95, 105, 128, 1)', fontSize: isMobile ? '15px' : '24px', fontWeight: '500', textAlign: isMobile ? 'center' : '' }}>Bridging the Gap Between Learning and Opportunity</p>
                        </div>
                        <div className="col-md-5" style={{ display: 'flex', alignItems: 'baseline', textAlign: isMobile ? 'center' : 'end' }}>
                            <img src={aboutimg} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" style={{ width: isMobile ? '360px' : '460px', }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Welcome section */}
            <div className='p-2'>
                <div className="container border shadow mt-5" style={{ display: 'flex', flexDirection: 'column', height: isMobile ? '100%' : '450px', borderRadius: '30px', background: "var(--GLASS, #FFFFFF69)" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                        <span style={{
                            background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            fontWeight: '700', fontSize: isMobile ? '28px' : '40px',
                            paddingTop: '37px',
                            textAlign: 'center',

                        }}>Welcome</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '27px', paddingBottom: isMobile ? '40px' : '' }}>
                        <span style={{ fontSize: '24px', fontWeight: '600', width: '799px' }}><b style={{ color: 'rgba(49, 68, 113, 1)' }}>The Skill Guru Foundation</b>, a pioneering force dedicated to reshaping the landscape of education, skill development, and mentorship. At the heart of our mission lies a profound commitment to empowering individuals through accessible, high-quality learning opportunities. We are not just an organization; we are a movement, a community of educators, mentors, volunteers, and learners united by a shared vision to create a world where knowledge knows no boundaries.</span>
                    </div>
                </div>
            </div>
            {/* Vision Section */}
            <div className='container' style={{ height: '', display: 'flex', justifyContent: isTablet ? 'center' : 'space-between', alignItems: 'center', flexDirection: isMobile ? 'column-reverse' : isTablet ? 'column-reverse' : '', marginTop: '65px', gap: isMobile ? '38px' : isTablet ? '38px' : '138px' }}>
                <div className='border shadow p-4' style={{ borderRadius: '20px', width: isMobile ? '100%' : isTablet ? "100%" : '50%', height: '100%', background: "var(--GLASS, #FFFFFF69)" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '24px' }}>
                            <div style={{ height: '80px', width: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #DEDFF1 0%, #F9D0D1 100%)', borderRadius: '40px' }}>
                                <img src={bulb} style={{ height: '60px', width: '57px' }} alt="" />
                            </div>
                            <p style={{ color: 'rgba(0, 17, 55, 1)', fontSize: '32px', fontWeight: '600' }}>Vision</p>
                        </div>
                        <p style={{ color: 'rgba(64, 64, 64, 1)', fontSize: '18px', fontWeight: '400' }}>We envision a world where education goes beyond classrooms, breaking socio-economic barriers to reach everyone. Our goal is to build a global network of learners and mentors, fostering knowledge-sharing, creativity, and personal growth. Through innovation, technology, and collaboration, we strive to drive meaningful change in education.</p>
                    </div>
                </div>
                <div>
                    <img src={mask} style={{ width: isMobile ? '100%' : '' }} alt="" />
                </div>
            </div>
            {/* mission section  */}
            <div className='container' style={{ height: '', display: 'flex', justifyContent: isTablet ? 'center' : 'space-between', alignItems: 'center', flexDirection: isMobile ? 'column' : isTablet ? 'column' : '', marginTop: '65px', gap: isMobile ? '38px' : isTablet ? '38px' : '138px' }}>
                <div>
                    <img src={mission} style={{ width: isMobile ? '100%' : '' }} alt="" />
                </div>
                <div className='border shadow p-4' style={{ borderRadius: '20px', width: isMobile ? '100%' : isTablet ? "100%" : '50%', height: '100%', background: "var(--GLASS, #FFFFFF69)" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '24px' }}>
                            <div style={{ height: '80px', width: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #DEDFF1 0%, #F9D0D1 100%)', borderRadius: '40px' }}>
                                <img src={punch} style={{ height: '60px' }} alt="" />
                            </div>
                            <p style={{ color: 'rgba(0, 17, 55, 1)', fontSize: '32px', fontWeight: '600' }}>Mission</p>
                        </div>
                        <p style={{ color: 'rgba(64, 64, 64, 1)', fontSize: '18px', fontWeight: '400' }}>Our mission is to bridge education and real-world skills, empowering underprivileged children and aspiring individuals through transformative learning. We foster growth by equipping students with academic knowledge, critical thinking, and life skills. Through digital platforms, mentorship, and hands-on projects, we inspire curiosity and lifelong learning.</p>
                    </div>
                </div>
            </div>
            {/* Our story section */}
            <div className='p-2'>
                <div className="container border shadow mt-5" style={{ display: 'flex', flexDirection: 'column', height: isMobile ? '100%' : '340px', borderRadius: '30px', background: 'linear-gradient(264.83deg, rgba(80, 95, 152, 0.35) 0.48%, rgba(255, 184, 184, 0.62) 65.06%)' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                        <span style={{
                            color: 'rgba(17, 27, 71, 1)',
                            fontWeight: '700', fontSize: isMobile ? '28px' : '40px',
                            paddingTop: '37px',
                            textAlign: 'center',

                        }}>Our Story</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '27px', paddingBottom: isMobile ? '40px' : '' }}>
                        <span style={{ fontSize: '18px', fontWeight: '400', width: '799px', color: 'rgba(0, 0, 0, 1)' }}>The Skill Guru Foundation was born out of a simple yet powerful idea: education is the cornerstone of progress. Recognizing the disparities in educational access and quality, we set out to create a platform that democratizes learning. What started as a small initiative has now grown into a dynamic organization, touching the lives of countless students and educators worldwide. Our journey is a testament to the belief that with the right support, every individual can achieve greatness.</span>
                    </div>
                </div>
            </div>
            {/* we do section  */}
            <div className="container mt-4">
                <p style={{ color: 'rgba(17, 27, 71, 1)', fontWeight: '700', fontSize: '32px', marginBottom: '25px' }}>What we do?</p>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-xl-3 g-4" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="col">
                        <div className="card shadow" style={{ borderRadius: '20px', height: isMobile ? '100%' : isTablet ? '100%' : isDesktop ? '100%' : '300px', background: "var(--GLASS, #FFFFFF69)" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <p style={{ background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: '24px', fontWeight: '600', textAlign: 'center', width: '80%' }}>Online education & Skill Development</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <p style={{ color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '18px', width: '80%' }}>We offer a comprehensive range of courses and workshops covering academic subjects, professional skills, and personal development. Our curriculum is designed to be flexible, engaging, and relevant to the demands of the modern world.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow" style={{ borderRadius: '20px', height: isMobile ? '100%' : isTablet ? '100%' : isDesktop ? '100%' : '300px', background: "var(--GLASS, #FFFFFF69)" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <p style={{ background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: '24px', fontWeight: '600', textAlign: 'center', width: '80%' }}>Mentorship Program</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <p style={{ color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '18px', width: '80%' }}>Connecting learners with industry experts, retired professionals, and skilled volunteers, we provide personalized guidance and support to help individuals navigate their educational and career paths.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow" style={{ borderRadius: '20px', height: isMobile ? '100%' : isTablet ? '100%' : isDesktop ? '100%' : '300px', background: "var(--GLASS, #FFFFFF69)" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <p style={{ background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: '24px', fontWeight: '600', textAlign: 'center', width: '80%' }}>Internship Opportunities</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <p style={{ color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '18px', width: '80%' }}>Through our diverse internship programs in Digital Marketing, Video Editing, UI/UX Design, Software Testing, AI, Business Strategy, and more, we offer hands-on experience, fostering practical skills and professional growth.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow" style={{ borderRadius: '20px', height: isMobile ? '100%' : isTablet ? '100%' : isDesktop ? '100%' : '300px', background: "var(--GLASS, #FFFFFF69)" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <p style={{ background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: '24px', fontWeight: '600', textAlign: 'center', width: '80%' }}>Online Peer-to -Peer Learning Platform</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <p style={{ color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '18px', width: '80%' }}>Our innovative platform allows users to post questions, share knowledge, and collaborate in real-time through messaging, video calls, and community interactions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow" style={{ borderRadius: '20px', height: isMobile ? '100%' : isTablet ? '100%' : isDesktop ? '100%' : '300px', background: "var(--GLASS, #FFFFFF69)" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <p style={{ background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 92.38%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: '24px', fontWeight: '600', textAlign: 'center', width: '80%' }}>Workshops and Events</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <p style={{ color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '18px', width: '80%' }}>We organize events, webinars, and workshops in schools, colleges, and corporate environments, promoting awareness about skill development and the importance of lifelong learning.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Our impack section  */}
            <p style={{ color: 'rgba(195, 77, 30, 1)', fontWeight: '700', fontSize: '36px', textAlign: 'center', marginTop: '30px', marginBottom: '20px' }}>Our Impact</p>
            <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '100%' : isTablet ? '100%' : '907px', display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : '', padding: isMobile ? '10px' : '' }}>
                    <div style={{ display: 'flex', gap: isMobile ? '17px' : '24px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', height: '48px', width: '48px', background: 'rgba(49, 68, 113, 1)', }}>
                            <img src={imp1} alt="" />
                        </div>
                        <p style={{ width: isMobile ? '80%' : '252px', color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '16px' }}>Empowered thousands of students with access to quality education and mentorship.</p>
                    </div>
                    <div style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', height: '48px', width: '48px', background: 'rgba(49, 68, 113, 1)', }}>
                            <img src={imp2} alt="" />
                        </div>
                        <p style={{ width: isMobile ? '80%' : '252px', color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '16px' }}>Successfully conducted numerous internship programs, providing real-world experience to aspiring professionals.</p>
                    </div>
                </div>
            </div>
            <div className="container" style={{ display: 'flex', justifyContent: 'center', marginTop: isMobile ? '-18px' : '100px' }}>
                <div style={{ width: isMobile ? '100%' : isTablet ? '100%' : '907px', display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : '', padding: isMobile ? '10px' : '' }}>
                    <div style={{ display: 'flex', gap: isMobile ? '17px' : '24px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', height: '48px', width: '48px', background: 'rgba(49, 68, 113, 1)', }}>
                            <img src={imp3} alt="" />
                        </div>
                        <p style={{ width: isMobile ? '80%' : '252px', color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '16px' }}>Established partnerships with educational institutions, NGOs, and corporate entities to expand our reach and impact.</p>
                    </div>
                    <div style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '40px', height: '48px', width: '48px', background: 'rgba(49, 68, 113, 1)', }}>
                            <img src={imp4} alt="" />
                        </div>
                        <p style={{ width: isMobile ? '80%' : '252px', color: 'rgba(64, 64, 64, 1)', fontWeight: '400', fontSize: '16px' }}>Built a strong network of dedicated volunteers, industry experts, and educators.</p>
                    </div>
                </div>
            </div>
            {/* Our value section */}

            <div className="container">
                <div className="col-md-12 ">
                    <div className=" p-2" style={{ borderRadius: '30px', border: isMobile ? '1px solid gray' : '1px solid gray', display: 'flex', flexDirection: isMobile ? 'column' : '', gap: isMobile ? '20px' : '', borderRadius: '30px', marginBottom: '0', alignItems: 'center' }}>
                        <div className="col-auto d-lg-block ">
                            <img className="bd-placeholder-img" src={value} alt="" style={{ width: isMobile ? '100%' : '400px', borderRadius: isMobile ? '30px' : '', borderTopLeftRadius: isMobile ? '30px' : '30px', borderBottomLeftRadius: '30px' }} />
                        </div>
                        <div className="col d-flex flex-column position-static" style={{ padding: isMobile ? '0' : '25px 50px', border: isMobile ? '' : '', padding: isMobile ? '10px' : '40px', borderRadius: isMobile ? '30px' : '' }}>
                            <div className='mb-3' style={{ display: 'flex', alignItems: isMobile ? '' : isTablet ? '' : 'center', justifyContent: isMobile ? '' : 'space-between', flexDirection: isMobile ? 'column' : isTablet ? 'column' : '' }}>

                            </div>
                            <h3 className="mb-0" style={{ fontSize: isMobile ? '20px' : isTablet ? '24px' : '32px', fontWeight: '500', color: '#111B47' }}>Our Value</h3>
                            <ul>
                                <li style={{ color: '#C34D1E', fontSize: isTablet ? '14px' : '20px', fontWeight: '600', marginTop: '10px' }}>Innovation :</li>
                                <p style={{ color: '#4F4F4F' }}>We embrace technology and new ideas to enhance learning experiences.</p>
                                <li style={{ color: '#C34D1E', fontSize: isTablet ? '14px' : '20px', fontWeight: '600', marginTop: '10px' }}>Integrity :</li>
                                <p style={{ color: '#4F4F4F' }}>We uphold the highest standards of honesty, transparency, and ethical behavior.</p>
                                <li style={{ color: '#C34D1E', fontSize: isTablet ? '14px' : '20px', fontWeight: '600', marginTop: '10px' }}>Collaboration :</li>
                                <p style={{ color: '#4F4F4F' }}>We thrive on partnerships and teamwork, valuing diverse perspectives and shared goals.</p>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            {/* Join us */}
            <h3 className="mt-5" style={{ fontSize: isMobile ? '22px' : isTablet ? '24px' : '32px', fontWeight: '600', color: '#111B47', textAlign: 'center' }}>Join Us</h3>
            <div className="container p-4" style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <p style={{ width: isMobile ? '100%' : '700px', fontWeight: '600', fontSize: '17px', textAlign: 'center' }}>At The Skill Guru Foundation, we are always looking for passionate individuals to join our mission. Whether you are an educator, volunteer, student, or professional, there is a place for you in our community. Together, we can make a difference, one learner at a time.</p>
            </div>
            <h3 className="mt-3" style={{ fontSize: isMobile ? '22px' : isTablet ? '24px' : '32px', fontWeight: '600', color: '#111B47', textAlign: 'center' }}>Contact Us</h3>
            <div className='p-4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden' }}>
                <p style={{ fontWeight: '600', fontSize: '17px', textAlign: 'center' }}>Visit our website: [www.theskillguru.org](http://www.theskillguru.org)  </p>
                <p style={{ fontWeight: '600', fontSize: '17px', textAlign: 'center' }}>Email: care@theskillguru.org   </p>
                <p style={{ fontWeight: '600', fontSize: '17px', textAlign: 'center' }}>Phone: +91-9560695530   </p>
                <p style={{ fontWeight: '600', fontSize: '17px', textAlign: 'center', wordBreak: 'break-word' }}>Follow us on social media: [Instagram: @theskillguru1](https://www.instagram.com/theskillguru1)</p>
                <p style={{ fontWeight: '600', fontSize: '17px', textAlign: 'center' }}>*The Skill Guru Foundation – Empowering Minds, Shaping Futures.*</p>
            </div>
            <Footer />
        </>
    )
}

export default Aboutus;