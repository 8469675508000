import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import GoogleIcon from '../assets/google.png';
import LogoIcon from './NavbarNew/logo.png';

const LoginCard = () => {
    const [signUpWithEmail, setSignUpWithEmail] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const firebaseConfig = {
        apiKey: "AIzaSyD9Sqx9_xDwtKsKHlXPD3UilY4fOu-c-yI",
        authDomain: "the-skill-guru---durgesh.firebaseapp.com",
        projectId: "the-skill-guru---durgesh",
        storageBucket: "the-skill-guru---durgesh.appspot.com",
        messagingSenderId: "910363851200",
        appId: "1:910363851200:web:9d21217318ec9316bcf025",
        measurementId: "G-6VFQH0M61L"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const idToken = await result.user.getIdToken();

            const response = await fetch('https://skill-guru-backend.vercel.app/auth/google', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idToken }),
            });

            if (!response.ok) throw new Error('Failed to authenticate with backend');

            const data = await response.json();
            localStorage.setItem('uid', data.uid);
            navigate(data.message === 'User created successfully' ? '/select-role' : '/');
        } catch (error) {
            alert('Google Sign-In failed. Please try again.');
        }
    };

    const handleLogin = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) return alert('Please enter a valid email address.');
        if (password.length < 6) return alert('Password should contain at least six characters.');

        try {
            const response = await fetch('https://skill-guru-backend.vercel.app/auth/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) throw new Error(`Login failed! ${await response.text()}`);

            const data = await response.json();
            localStorage.setItem('uid', data.user.uid);
            navigate('/');
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div style={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            width: '494px', height: '364px', background: 'rgba(255, 255, 255, 0.41)', backdropFilter: 'blur(50px)',
            boxShadow: '0px 2px 3.8px rgba(105, 105, 105, 0.31)', borderRadius: '28.46px',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY: 'auto',
           
        }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src={LogoIcon} alt="signuplogo" style={{ width: '96.9px', marginTop: '24px' }} />
            </div>
            <div style={{ marginLeft: '80px', width: '334px' }}>
                <div style={{
                    display: 'flex', justifyContent: 'center', fontSize: '33px', fontWeight: '600',
                    background: 'linear-gradient(95.51deg, #B74C24 3.7%, #34446F 98.2%)', WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                }}>
                    <p>Log in</p>
                </div>
                <div>
                    {signUpWithEmail ? (
                        <>
                            <div style={{ padding: '10px 0px' }}>
                                <input type="email" placeholder="Email" style={{ paddingLeft: '12px', width: '100%', height: '55px', border: '1px solid #aaa', borderRadius: '4px' }}
                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div style={{ padding: '10px 0px' }}>
                                <div style={{ position: 'relative' }}>
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Password" style={{ paddingLeft: '12px', width: '100%', height: '55px', border: '1px solid #aaa', borderRadius: '4px' }}
                                        value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <button onClick={() => setShowPassword(!showPassword)} style={{
                                        position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)',
                                        background: 'none', border: 'none', cursor: 'pointer'
                                    }}>
                                        {showPassword ? '👁️' : '👁️‍🗨️'}
                                    </button>
                                </div>
                            </div>
                            <div style={{ padding: '10px 0px 0px 0px', marginLeft: "12px" }}>
                                <button onClick={handleLogin} style={{
                                    width: "318px", height: "40px", borderRadius: "10px", cursor: 'pointer',
                                    background: (email && password.length > 5) ? 'rgba(72, 94, 146, 1)' : 'rgba(221, 221, 221, 1)',
                                    color: (email && password.length > 5) ? 'rgba(255, 255, 255, 1)' : 'rgba(119, 119, 119, 1)',
                                    border: 'none', marginTop: "20px"
                                }}>Log in</button>
                            </div>
                        </>
                    ) : (
                        <button style={{
                            width: "318px", height: "40px", borderRadius: "10px", cursor: 'pointer',
                            background: 'rgba(72, 94, 146, 1)', color: 'rgba(255, 255, 255, 1)', border: 'none', marginTop: "20px"
                        }} onClick={() => setSignUpWithEmail(true)}>Login with Email</button>
                    )}
                    <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '13px', color: 'rgba(81, 81, 81, 1)' }}>
                        or continue with
                    </div>
                    <div onClick={signInWithGoogle} style={{
                        width: "334px", display: "flex", cursor: 'pointer', justifyContent: "center", marginTop: "10px"
                    }}>
                        <div style={{
                            background: "white", borderRadius: "7px", padding: "9px 45px", border: "0.71px solid #ccc"
                        }}>
                            <span>Continue with </span>
                            <img src={GoogleIcon} alt="Google Icon" style={{ width: "17px", height: "17px" }} />
                        </div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <p style={{ fontSize: "13px", fontWeight: "400" }}>Already have an account?</p>
                        <Link to='/signup' style={{ textDecoration: "none", fontSize: "16px", fontWeight: "500" }}>Sign-up</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginCard;
