import React from 'react';
import Logo from "./logo.png";
import "../NavbarNew/Navbar.css";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const isDesktop = useMediaQuery({ minWidth: 1025 });
    const navigate = useNavigate();
    return (
        <>
            <div className='sticky-top  shadow-lg' style={{ background: "var(--GLASS, #FFFFFF99)"}}>
                <nav className=" container navbar navbar-expand-lg navbarmain " style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                   
                }}>
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <div className="logosection" style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <img src={Logo} alt="Skill Guru Logo" style={{ height: '50px' }} />
                                <p style={{
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '30px',
                                    fontWeight: '600',
                                    color: 'linear-gradient(90deg, #C34D1E 0%, #314471 100%),',
                                    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                    margin: '0',
                                    letterSpacing: '0.05em'

                                }}>Skill Guru</p>
                            </div>
                        </a>
                        <button className="navbar-toggler" style={{ outline: 'none' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav" style={{ gap: '15px', display: 'flex', alignItems: isMobile ? 'start' : isTablet ? 'start' : 'center' }}>
                                
                                
                                <li className="nav-item" onClick={()=>navigate('/signup')}>
                                    <a className="nav-link active join" href="#">
                                        <button className='signupbtn' style={{
                                            padding: '10px 20px', fontSize: '16px', borderRadius: '10px',outline:'none', background: '#485E92', color: '#fff', border: 'none', cursor: 'pointer',
                                        }}>Sign up/Log in</button>
                                    </a>
                                </li>

                                {/* <li className="nav-item">
                                    <a className="nav-link active join" href="#">
                                        <button className='signupbtn' style={{
                                            padding: '10px 20px', fontSize: '16px', borderRadius: '10px',outline:'none', background: 'rgba(202, 85, 42, 1)', color: '#fff', border: 'none', cursor: 'pointer',
                                        }}>Donate!</button>
                                    </a>
                                </li> */}
                            </ul>
                        </div>

                    </div>
                </nav>
            </div>

        </>
    );
}

export default Navbar;