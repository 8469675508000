import React from 'react';
import { useMediaQuery } from 'react-responsive';
import '../Donate/Donate.css';
import Newnavbar from '../../components/NewNav/Newnavbar';
import Navbar from '../../components/NavbarNew/Navbar';
import Footer from '../../components/Footer/Footer';

function Donate() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const uid = localStorage.getItem('uid')
    return (
        <>
        {uid?<Newnavbar/>:<Navbar/>}
            <div className="container mt-4">
                <span style={{ fontWeight: '600', fontSize: isMobile ? '27px' : '54px', color: 'rgba(49, 58, 96, 1)', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>Make A</span>
                <span style={{
                    background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 72.38%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: '600', fontSize: isMobile ? '27px' : '54px',
                    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
                }}> Difference</span>
                <ul style={{ marginTop: '20px' }}>
                    <li style={{ fontWeight: '500', fontSize: '19px', color: 'rgba(64, 64, 64, 1)', lineHeight: '40px' }}>Skills Guru Foundation is registered  NCO under India Trust Act 1882,  Trust Registration Number - 2024/4/IV/1195.</li>
                    <li style={{ fontWeight: '500', fontSize: '19px', color: 'rgba(64, 64, 64, 1)', lineHeight: '40px' }}>Our NGO Darpan Registration No. HR/2024/0436805.</li>
                    <li style={{ fontWeight: '500', fontSize: '19px', color: 'rgba(64, 64, 64, 1)', lineHeight: '40px' }}>Registered under the Foreign Contribution Regulation Act (FCRA)</li>
                    <li style={{ fontWeight: '500', fontSize: '19px', color: 'rgba(64, 64, 64, 1)', lineHeight: '40px' }}>Our Pan Card Number AAETT9802F</li>
                </ul>
                <br /><br />
                <span style={{ fontWeight: '600', fontSize: isMobile ? '27px' : '54px', color: 'rgba(49, 58, 96, 1)', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>Donate</span>
                <span style={{
                    background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 72.38%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: '600', fontSize: isMobile ? '27px' : '54px',
                    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
                }}> Now</span>

                <div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <br />
                        <div className='border shadow ' style={{ width: isMobile ? '100%' : '722px', height: isMobile ? '' : '672px', padding: isMobile ? '10px' : '50px', borderRadius: '20px' }}>
                            <br />
                            <p style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: '600', fontSize: '24px', lineHeight: '14px' }}>Donate</p>
                            <br />
                            {/* contry select */}
                            <div style={{ display: 'flex', gap: '30px', flexDirection: isMobile ? 'column' : '' }}>
                                <div className='w-100'>
                                    <div>
                                        <span style={{ background: '#fff', padding: '4px 8px', position: 'absolute', marginTop: '-17px', marginLeft: '15px' }}>
                                            <span style={{ color: 'rgba(73, 69, 79, 1)', fontSize: '16px', fontWeight: '400' }}>Select Country</span>
                                        </span>
                                    </div>
                                    <select className="form-select w-100 p-3 " style={{ boxShadow: 'none' }}>
                                        <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>India</option>
                                        <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>United States</option>
                                        <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>United Kingdom</option>
                                        <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>Canada</option>
                                        <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>Australia</option>
                                    </select>
                                </div>
                                <div className='w-100'>
                                    <div>
                                        <span style={{ background: '#fff', padding: '4px 8px', position: 'absolute', marginTop: '-17px', marginLeft: '15px' }}>
                                            <span style={{ color: 'rgba(73, 69, 79, 1)', fontSize: '16px', fontWeight: '400' }}>Select Currency</span>
                                        </span>
                                    </div>
                                    <select className="form-select w-100 p-3 " style={{ boxShadow: 'none' }}>
                                        <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>INR</option>
                                        <option value="usd" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>USD</option>
                                        <option value="eur" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>EUR</option>
                                        <option value="gbp" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>GBP</option>
                                        <option value="aud" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>AUD</option>
                                        <option value="cad" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>CAD</option>
                                        <option value="jpy" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>JPY</option>
                                    </select>
                                </div>
                            </div>
                            <br />
                            <p style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: '600', fontSize: '24px', lineHeight: '14px' }}>Donate amount</p>
                            <br />
                            <div>
                                <div style={{ display: 'flex', gap: isMobile ? '15px' : '25px', flexDirection: isMobile ? 'column' : '' }}>
                                    <div className='border w-100' id='btn' style={{ gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center' }}>
                                        <span style={{ fontWeight: '500', fontSize: isMobile ? '17px' : '20px' }}>₹5000 </span>
                                    </div>
                                    <div className='border w-100' id='btn' style={{ gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center' }}>
                                        <span style={{ fontWeight: '500', fontSize: isMobile ? '17px' : '20px' }}>₹10,000 </span>
                                    </div>
                                    <div className='border w-100' id='btn' style={{ gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center' }}>
                                        <span style={{ fontWeight: '500', fontSize: isMobile ? '17px' : '20px' }}>₹25,000 </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <div style={{ display: 'flex', gap: isMobile ? '15px' : '25px', flexDirection: isMobile ? 'column' : '' }}>
                                    <div className='border w-100' id='btn' style={{ gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center' }}>
                                        <span style={{ fontWeight: '500', fontSize: isMobile ? '17px' : '20px' }}>₹5000 </span>
                                    </div>
                                    <div className='border w-100' id='btn' style={{ gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center' }}>
                                        <span style={{ fontWeight: '500', fontSize: isMobile ? '17px' : '20px' }}>₹10,000 </span>
                                    </div>
                                    <div className='border w-100' id='btn' style={{ gap: '8px', height: isMobile ? '42px' : '64px', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center' }}>
                                        <span style={{ fontWeight: '500', fontSize: isMobile ? '17px' : '20px' }}>₹25,000 </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='mt-4' style={{ width: isMobile ? '100%' : '50%', marginTop: isMobile ? '30px' : '' }}>
                                <div>
                                    <span style={{ background: '#fff', padding: '4px 8px', position: 'absolute', marginTop: '-17px', marginLeft: '15px' }}>
                                        <span style={{ color: 'rgba(73, 69, 79, 1)', fontSize: '16px', fontWeight: '400' }}>Enter your amount </span>
                                    </span>
                                </div>
                                <select className="form-select w-100 p-3 " style={{ boxShadow: 'none' }}>
                                    <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>10,000</option>
                                    <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>5000</option>
                                    <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>25,000</option>
                                    <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>50,000</option>
                                    <option value="" style={{ fontWeight: '400', fontSize: '14px', color: 'rgba(147, 143, 154, 1)' }}>1,00,000</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                <div style={{ background: 'rgba(61, 81, 128, 1)', gap: '8px', height: isMobile ? '38px' : '47px', borderRadius: '30px', display: 'flex', alignItems: 'center', cursor: 'pointer', width: isMobile ? '250px' : '335px', justifyContent: 'center' }}>
                                    <span style={{ fontWeight: '500', fontSize: isMobile ? '16px' : '20px', color: 'rgba(255, 255, 255, 1)', }}><i class="fa-solid fa-arrow-right-long"></i> Next</span>
                                </div>
                            </div>
                        </div>
                        {/* Personal Details */}

                        <div className='border shadow ' style={{ display: 'block', width: isMobile ? '100%' : '522px', height: isMobile ? '' : '672px', padding: isMobile ? '10px' : '50px', borderRadius: '20px' }}>
                            <br />
                            <p style={{ color: 'rgba(0, 0, 0, 1)', fontWeight: '600', fontSize: '24px', lineHeight: '14px', textAlign: 'center' }}>Add personal details</p>
                            <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                                <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                                    Enter Your Full Name
                                </span>
                                <input
                                    placeholder="Type Your Full Name"
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        paddingRight: '40px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        resize: 'none',
                                        outline: 'none'
                                    }}
                                ></input>
                            </div>
                            <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                                <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                                    Enter Your Email
                                </span>
                                <input
                                    placeholder="Type Your Email"
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        paddingRight: '40px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        resize: 'none',
                                        outline: 'none'
                                    }}
                                ></input>
                            </div>
                            <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                                <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                                    Enter Your Mobile Number
                                </span>
                                <input
                                    placeholder="Type Your Mobile Number "
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        paddingRight: '40px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        resize: 'none',
                                        outline: 'none'
                                    }}
                                ></input>
                            </div>
                            <div style={{ position: 'relative', width: '100%', marginTop: '37px' }}>
                                <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                                    Enter Your Address
                                </span>
                                <input
                                    placeholder="Type Your Address"
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        paddingRight: '40px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        resize: 'none',
                                        outline: 'none'
                                    }}
                                ></input>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                <div style={{ background: 'rgba(202, 85, 42, 1)', gap: '8px', height: isMobile ? '38px' : '47px', borderRadius: '30px', display: 'flex', alignItems: 'center', cursor: 'pointer', width:'100%', justifyContent: 'center' }}>
                                    <span style={{ fontWeight: '500', fontSize: isMobile ? '16px' : '20px', color: 'rgba(255, 255, 255, 1)', }}>Pay</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Footer/>
        </>
    )
}

export default Donate