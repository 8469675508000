


import React, { useEffect, useState } from 'react';
import '../Postquetion_popup/Postquestion_pop_up.css';
import { useMediaQuery } from 'react-responsive';
import post from '../Postquetion_popup/post.png';
import deletes from '../Postquetion_popup/delete.png';
import { toast } from 'react-toastify';

function Postquestion_pop_up() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [image, setImage] = useState(null);
    const [questionText, setQuestionText] = useState('');
    const handleQuestionChange = (event) => {
        setQuestionText(event.target.value);
    };
    const handleImageUpload = (event) => {
        setImage(event.target.files[0]);
    };

    const uid = localStorage.getItem('uid');
    const handlePostQuestion = async () => {
        if (questionText.length !== 0 && selectedSkills.length !== 0) {
            const formData = new FormData();
            formData.append("uid", uid);
            formData.append("questionText", questionText);
            formData.append("selectedSkills", JSON.stringify(selectedSkills));
            if (image) formData.append("image", image); // Send image file
    
            try {
                const response = await fetch('https://skill-guru-backend.vercel.app/post-question/post-question', {
                    method: 'POST',
                    body: formData,  // Don't set 'Content-Type', browser will handle it
                });
    
                const data = await response.json();
                if (!response.ok) throw new Error(data.error);
                console.log("Success:", data.message);
                toast.info("Posted question successfully.")
            } catch (error) {
                console.error('Error:', error.message);
            }
        } else {
            toast.info('Please fill all fields');
            console.log(questionText.length,selectedSkills)
        }
    };
    


    useEffect(() => {
        const fetchSkillsData = async () => {
            try {
                const response = await fetch("https://skill-guru-backend.vercel.app/skills-list/skills-list");
                if (!response.ok) throw new Error("Failed to fetch skills");
                const data = await response.json();
                setSkills(data.skillsArray || []);
            } catch (error) {
                console.error("Error fetching skills:", error);
            }
        };
        fetchSkillsData();
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        
        if (skills.includes(value) && !selectedSkills.includes(value)) {
            handleSelectSkill(value);
        }
    };
    const handleSelectSkill = (skill) => {
        if (skill && !selectedSkills.includes(skill)) {
            setSelectedSkills(prevSkills => [...prevSkills, skill]);
            setSearchTerm('');
            console.log(selectedSkills[0])
        }
    };

    const handleRemoveSkill = (skill) => {
        setSelectedSkills(selectedSkills.filter(s => s !== skill));
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', padding: '10px' }}>
            <div className='border shadow' style={{ background: 'white', width: '797px', height: '620px', borderRadius: '20px', display: 'flex', flexDirection: 'column', padding: isMobile ? '20px' : '60px' }}>
                <p style={{ fontWeight: '600', fontSize: '24px', color: 'black' }}>Post Question</p>
                <div>
                    <input
                        type="text"
                        placeholder="Select a skill/subject..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="form-control w-100 p-2"
                        list="skills-list"
                    />
                    <datalist id="skills-list">
                        {skills.filter(skill => skill.toLowerCase().includes(searchTerm.toLowerCase())).map((skill, index) => (
                            <option key={index} value={skill} onClick={() => handleSelectSkill(skill)}></option>
                        ))}
                    </datalist>
                    <div style={{ marginTop: '10px' }}>
                        {selectedSkills.map((skill, index) => (
                            <span key={index} style={{ margin: '5px', padding: '5px 10px', background: '#ccc', borderRadius: '5px', display: 'inline-block' }}>
                                {skill} <span style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }} onClick={() => handleRemoveSkill(skill)}>x</span>
                            </span>
                        ))}
                    </div>
                </div>
                <div style={{ position: 'relative', width: '100%', marginTop: '60px' }}>
                    <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                        Ask Question
                    </span>
                    <textarea
                        placeholder="Type Your Question"
                        value={questionText}
                        onChange={handleQuestionChange}
                        style={{
                            width: '100%',
                            padding: '18px 15px 95px 15px',
                            paddingRight: '40px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            resize: 'none',
                            outline: 'none'
                        }}
                    ></textarea>
                    <span style={{ position: 'absolute', bottom: '10px', right: '10px', cursor: 'pointer' }}>
                        <i className="fa-solid fa-camera" style={{ color: 'rgba(44, 44, 45, 1)', fontSize: '25px' }}></i>
                    </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', marginTop: '40px' }}>
                    <div style={{ position: 'relative', width: '100%', marginTop: '20px' }}>
                        <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginTop: '10px' }} />
                        {image && <p style={{ marginTop: '10px' }}>Selected Image: {image.name}</p>}
                    </div>

                    {/* Disable, Post Button */}
                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: '', gap: '20px', marginTop: '40px' }}>
                        
                        <div style={{ background: 'rgba(202, 85, 42, 1)', gap: '8px', height: isMobile ? '35px' : '50px', borderRadius: '30px', display: 'flex', alignItems: 'center', cursor: 'pointer', width: isMobile ? '100px' : '150px', justifyContent: 'center' }} onClick={handlePostQuestion} >
                            <span style={{ display: 'flex', alignItems: 'center', gap: '6px', fontWeight: '500', fontSize: isMobile ? '14px' : '17px', color: 'rgba(255, 255, 255, 1)', }}> <img src={post} style={{ height: '20px' }} alt="" /> Post</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
        
    );
}

export default Postquestion_pop_up;
