import React, { useState, useEffect } from 'react';
import PurpleCircle from '../components/PurpleCircle';
import PinkCircle from '../components/PinkCircle';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const SelectRole = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [items, setItems] = useState([]); // Store skills from API
  const location = useLocation();
  const { selectedRoles } = location.state || {};
  const role = selectedRoles?.[0] || "your";


  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        const response = await fetch("https://skill-guru-backend.vercel.app/skills-list/skills-list");
        if (!response.ok) throw new Error("Failed to fetch skills");
        const data = await response.json();
        // setSkills(data.skillsArray || []);
        setItems(data.skillsArray || [])
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };
    fetchSkillsData();
  }, []);


  // Filter items based on the search term
  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(item)
        ? prevItems.filter((i) => i !== item) // Remove item
        : [...prevItems, item] // Add item
    );
    setSearchTerm('');
  };


  const handleNext = async () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one skill.");
      return;
    }
  
    // Get the user ID from local storage
    const uid = localStorage.getItem("uid"); // Assuming the UID is stored under the key "uid"
  
    if (!uid) {
      alert("User ID not found. Please log in.");
      return;
    }
  
    const payload = {
      uid: uid, // Use the UID from local storage
      skills: selectedItems,
      role: [role],
    };
  
    try {
      const response = await fetch("https://skill-guru-backend.vercel.app/skills/store-skills", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log("Skills stored successfully:", data);
        navigate("/"); // Navigate to another page after success
      } else {
        console.error("Error storing skills:", data.error);
      }
    } catch (error) {
      console.error("Failed to store skills:", error);
    }
  };
  

  return (
    <div style={{ position: 'relative' }}>
      {/* Background Circles */}
      <div className="signup-circle1">
        <PurpleCircle />
      </div>
      <div className="signup-circle2">
        <PinkCircle />
      </div>

      {/* Select Role Card */}
      <div
        className="select-role-card"
        style={{
          background: 'var(--GLASS, rgba(255, 255, 255, 0.41))',
          backdropFilter: 'blur(50px)',
          boxShadow: '0px 2px 3.8px 0px rgba(105, 105, 105, 0.31)',
          width: !isMobile ? '494px' : '354px',
          padding: '20px',
          position: 'absolute',
          minHeight: !isMobile ? '664px' : '530px',
          zIndex: 2,
          top: !isMobile ? '50%' : '70%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '28.46px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'height 0.3s ease',
        }}
      >
        <div>
          <img
            src="https://s3-alpha-sig.figma.com/img/757c/cc3f/aae7b0df685d3564b13a0210a37ff70e?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=D3LJFKZ1kJ0BS5bFL6nXHxr6-zub4UU5x7pSfFP9A3-BbvxHPGHDIrxpRn3SqlY7J9lXHjVB7Q2fv2oH6CaWcHgkIhMeYTT3nLHBfmXm9IVESyGv4zUeOj~G08Q2KHji3wuYGXG9l5Hs47PmiG0iSdqCh~DIhtvHsM9wvqdFIG5xmMMKAsSZfQG9IuXiPpXoKVJS7Uf6hdaB2xwGhkqNw1KhXz7RbKa7-TIVqvvtOGG1H039Jky1cvig6ZNT9EQN9DLErDhmkU411A1mBiDhZ~7bh2ZqQdZX0~7oR2LjgfBtvWkQ~-LlE3qGhqig-VJwP~twQX1LGiiBMXkAbqTUyw__"
            style={{ width: '45px', height: '64px' }}
            alt="logo"
          />
        </div>
        <div>
          <p className="colored-text" style={{ fontSize: isMobile ? '12px' : '' }}>
            Add your {role} skills
          </p>
        </div>

        <div style={{ position: 'relative', width: '334px' }}>
          <label
            htmlFor="input-field"
            style={{
              position: 'absolute',
              top: '-8px',
              left: '15px',
              background: '#fff',
              padding: '0 5px',
              fontSize: '12px',
              color: 'gray',
              zIndex: 1,
            }}
          >
            Your Label
          </label>

          {/* Input with Dropdown Button */}
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              id="input-field"
              value={searchTerm}
              placeholder="Search or select..."
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={() => setIsDropdownOpen(true)}
              style={{
                width: '100%',
                padding: '10px',
                paddingRight: '30px',
                borderRadius: '5px',
                border: '3px solid rgba(72, 94, 146, 1)',
              }}
            />
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              style={{
                position: 'absolute',
                right: '10px',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
                color: 'rgba(72, 94, 146, 1)',
              }}
            >
              {isDropdownOpen ? '▲' : '▼'}
            </button>
          </div>
        </div>

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div
            style={{
              marginTop: '10px',
              width: '334px',
              maxHeight: '150px',
              overflowY: 'auto',
              backgroundColor: '#fff',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              zIndex: 1000,
              padding: '5px',
            }}
          >
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleItemClick(item)}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #f0f0f0',
                    backgroundColor: selectedItems.includes(item)
                      ? 'rgba(72, 94, 146, 1)'
                      : '#fff',
                    color: selectedItems.includes(item) ? 'white' : 'black',
                    borderRadius: "7px",
                    margin: "3px",
                  }}
                  onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = selectedItems.includes(item)
                    ? 'rgba(72, 94, 146, 1)'
                    : '#f5f5f5')
                  }
                  onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = selectedItems.includes(item)
                    ? 'rgba(72, 94, 146, 1)'
                    : '#fff')
                  }
                >
                  {item}
                </div>
              ))
            ) : (
              <div style={{ padding: '10px', textAlign: 'center', color: '#999' }}>
                No items found
              </div>
            )}
          </div>
        )}

        <button
          onClick={handleNext}
          style={{
            width: '334px',
            height: '40px',
            background: 'rgba(72, 94, 146, 1)',
            borderRadius: '10px',
            color: 'white',
            marginTop: '20px',
            cursor: "pointer",
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectRole;
