import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase Config values
const firebaseConfig = {
  apiKey: "AIzaSyD9Sqx9_xDwtosKHlXPD3UilY4fOu-c-yI",
  authDomain: "the-skill-guru---durgesh.firebaseapp.com",
  projectId: "the-skill-guru---durgesh",
  storageBucket: "the-skill-guru---durgesh.appspot.com",
  messagingSenderId: "910363851200",
  appId: "1:910363851200:web:9d21217318ec9316bcf025",
  measurementId: "G-6VFQH0M61L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);






const handleStoreFcm = async (token) => {
  const uid = localStorage.getItem("uid")
  if(uid){
    try {
      const response = await fetch('https://skillgurutest.onrender.com/store-fcm/store-fcm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid,token }),
      });
      if (!response.ok) {
        const errorText = await response.text(); // Get error response text
        throw new Error(`Failed to store fcm! ${errorText}`); // Corrected string interpolation
      }
  
      const data = await response.json();
      console.log("data", data);
      
      localStorage.getItem("uid")
      
    } catch (error) {
      console.error('Error details:', error); // Log the error details
      alert(error.message); // Show error message
    }
  }
  
}














// Request FCM Token
export const requestFCMToken = async () => {
  const permission = await Notification.requestPermission();
  console.log("Permission:", permission);

  if (permission !== "granted") {
    console.error("Notifications permission denied.");
    return;
  }
  try {
    const token = await getToken(messaging, {
      vapidKey: "BFK_6eI8eOW3CrF3by1bhMBnHiX4CT3A9Uj7WRxewz1_DrDSddzcFXULcQ-pU27JEJ1T0hQar59RAdk9NR5Cnms",
    });
    console.log("FCM Token:", token);
    await handleStoreFcm(token)
    return token;
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

// Listen to Messages
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

onMessage(messaging, (payload) => {
  console.log("Message received:", payload);
});
