import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import homeimg from "../Home/home.png";
import network from "../../assets/networking.png";
import blue from "../../assets/blue circle.png";
import pink from "../../assets/pink circle.png";
import education from "../../assets/Education.png";
import bluecircle from "../../assets/blue circle.png";
import Navbar from '../../components/NavbarNew/Navbar';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Newnavbar from '../../components/NewNav/Newnavbar';

function Home() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const isDesktop = useMediaQuery({ minWidth: 1025 });
    const [reload,setReload] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        const uid = localStorage.getItem('uid')
        if(uid){
            navigate('/')
        }
        
    }, []);
    const uid = localStorage.getItem('uid')
    return (
        <>
            
            <div style={{
                background: 'linear-gradient(180deg, rgba(80, 95, 152, 0.35) 0%, rgba(255, 184, 184, 0.62) 99%)',

            }}>
                <div className="container " >
                    <div className="row featurette align-items-center bg" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                        <div className="col-md-7">
                            <h1 className="featurette-heading mentoringheading" style={{fontSize:isMobile ? '30px' :'52px',fontWeight:'700',color:'rgba(49, 58, 96, 1)'}}>
                            24x7 Live, One-on-One Learning Made Easy!
                            </h1>
                            <br />
                            <p className="lead empower">
                                Empower your journey with expert mentorship and knowledge to achieve success.
                            </p>

                            <div className='mb-4' style={{ display: 'flex', justifyContent: isMobile ? 'start' : '' }}>
                                <div style={{ width: isMobile ? '100%' : isTablet ? '' : '448px', display: 'flex', justifyContent: 'space-between', padding: '8px 16px', borderRadius: '10px', background: 'rgba(243, 244, 255, 1)' }}>
                                    <input type="text" placeholder='Ask Questions...' style={{width:"70%", fontSize: isMobile ? '13px' : '17px', fontWeight: '400', padding: '4px', border: 'none', outline: 'none', background: 'transparent' }} />
                                    <button onClick={()=>{uid?navigate('/questions'):navigate('/signup')}} style={{ fontSize: isMobile ? '13px' : '17px', background: 'rgba(72, 94, 146, 1)', borderRadius: '10px', color: 'rgba(255, 255, 255, 1)', fontWeight: '400', outline: 'none', border: 'none', padding: '6px 13px' }}>Ask now</button>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <img
                                src={homeimg}
                                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                width="500"
                                height="500"
                                alt="Mentoring"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            {/* What is skill gutru section here */}
            <div className="" style={{ padding: '0 5%' }}>
                <div className="main" style={{ position: "relative", display: "flex", flexDirection: 'column', minHeight: isMobile ? '75vh' : '80vh', }}>
                    <div className="cont" style={{ position: 'absolute', top: '0', width: '100%', zIndex: "1" }}>
                        <div className="container rounded-3 bgbody border"
                            style={{
                                height: isMobile ? '' : isTablet ? '100%' : "460px",
                                width: isMobile ? '100%' : isTablet ? '100%' : '1400px',
                                background: "var(--GLASS, #FFFFFF69)",

                            }}>
                            <div className="pad" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: isMobile ? '10px' : '30px' }}>
                                <h3 className="display-4 mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '4px' }}>
                                    <span style={{ color: '#313A60', fontWeight: '700', fontSize: isMobile ? '25px' : '40px' }}>What is <span style={{ color: '#B24B47', fontWeight: '700' }}>Skill Guru?</span></span>
                                </h3>
                                <p className="lead my-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ fontWeight: '500', fontSize: isMobile ? '18px' : '24px', maxWidth: '90%', textAlign: 'center' }}>
                                        Skill Guru’s mission is to provide students with free, high-quality education and mentorship to help them succeed. We strive to make learning simple and accessible for everyone, offering the support and resources needed to reach their full potential. With personalized guidance and a commitment to growth, Skill Guru is here to inspire students and help them achieve their goals.
                                    </div>
                                </p>
                                <button onClick={()=>navigate('/about-us')} className='mb-4' style={{ padding: '10px 20px', fontSize: isMobile ? '16px' : '20px', borderRadius: '5px', background: '#485E92', color: 'white', border: 'none', cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>
                                    Learn more
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="blueimg">
                        <img src={blue} alt="Blue Circle"
                            style={{ width: isMobile ? '150px' : isTablet ? '150px' : '272px', marginTop: '50px', marginLeft: isMobile ? '20px' : '110px' }} />
                    </div>
                    <div className="pinkimg" style={{ textAlign: 'end' }}>
                        <img src={pink} alt="Pink Circle"
                            style={{ width: isMobile ? '140px' : isTablet ? '140px' : '216px', marginRight: isMobile ? '10px' : '160px' }} />
                    </div>
                </div>
            </div>
            {/* ask and answer */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: isMobile ? '' : '-150px' }}>
                <div className='container align-items-center' style={{ minHeight: isMobile ? '60vh' : '50vh' }}>
                    <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content- w-100" style={{ minHeight: '50px' }}>
                            <img
                                src={bluecircle}
                                alt="Blue Circle"
                                style={{
                                    width: isMobile ? '150px' : isTablet ? '250px' : '272px',
                                    position: 'absolute',
                                }}
                            />
                        </div>
                        <div
                            className="row featurette align-items-center w-100 d-flex justify-content-center"
                            style={{ zIndex: 999 }}
                        >
                            <div className="col-md-7 order-md-2 text-center">
                                <div className="h-100 p-4 p-md-5 border rounded-3" style={{ background: "var(--GLASS, #FFFFFF69)" }}>
                                    <h3 className="display-4 mb-5" style={{ color: '#313A60', fontWeight: '700', fontSize: isMobile ? '23px' : '30px' }}>
                                        Ask and Answer <span style={{ color: '#B24B47' }}>Questions</span>
                                    </h3>
                                    <p style={{ fontWeight: '500', fontSize: '20px', textAlign: 'center' }}>
                                        Connect with a knowledge-sharing community. Post your questions to get expert solutions, or share your expertise by helping others.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-5 order-md-1 d-flex justify-content-center">
                                <img
                                    src={education}
                                    alt="Education"
                                    className="img-fluid"
                                    style={{ width: '100%', maxWidth: isMobile ? '' : '500px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Personalized */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: isMobile ? '' : 'px' }}>
                <div className='container align-items-center ' style={{ minHeight: isMobile ? '60vh' : '50vh' }}>
                    <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content- w-100" style={{ minHeight: '50px' }}>
                            <img
                                src={pink}
                                alt="Blue Circle"
                                style={{
                                    width: isMobile ? '150px' : isTablet ? '250px' : '172px',
                                    position: 'absolute',
                                }}
                            />
                        </div>
                        <div
                            className="row featurette align-items-center w-100 d-flex justify-content-center"
                            style={{ zIndex: 999 }}
                        >
                            <div className="col-md-7 order-md-1 text-center">
                                <div className="h-100 p-4 p-md-5 border rounded-3" style={{ background: "var(--GLASS, #FFFFFF69)" }}>
                                    <h3 className="display-4 mb-5" style={{ color: '#B24B47', fontWeight: '700', fontSize: isMobile ? '23px' : '30px' }}>
                                        Networking <span style={{ color: '#313A60' }}>Personalized</span>

                                    </h3>
                                    <p style={{ fontWeight: '500', fontSize: '20px', textAlign: 'center' }}>
                                        Connect with a knowledge-sharing community. Post your questions to get expert solutions, or share your expertise by helping others.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2 d-flex justify-content-center">
                                <img
                                    src={network}
                                    alt="Education"
                                    className="img-fluid"
                                    style={{ width: '100%', maxWidth: isMobile ? '' : '500px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial */}

        <Footer/>
        </>
    );
}

export default Home;