import React from 'react'
import PinkCircle from '../components/PinkCircle'
import PurpleCircle from '../components/PurpleCircle'
import LoginCard from '../components/LoginCard'
import Navbar from '../components/NavbarNew/Navbar'

const Login = () => {
  return (
    <div className=''>
      <Navbar/>
        <div className='signup-circle1' >
            <PurpleCircle/>
        </div>
        <div className='signup-circle2' >
            <PinkCircle/>
        </div>
        <div>
            <LoginCard/>
        </div>
    </div>
  )
}

export default Login