import React, { useEffect, useState, useCallback } from "react";
import "../Postquestion/Postquestion.css";
import { useMediaQuery } from "react-responsive";
import icon from "../Postquestion/icon.png";
import Newnavbar from "../../components/NewNav/Newnavbar";
import { useNavigate } from "react-router-dom";
import MainHome from "../MainHome";

function Postquestion() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  // Fetch questions with pagination
  const fetchQuestionsData = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://skill-guru-backend.vercel.app/questions/questions?page=${page}&limit=5`
      );
      if (!response.ok) throw new Error("Failed to fetch questions");

      const data = await response.json();
      if (data.questions.length === 0) setHasMore(false);
      console.log(data)

      setQuestions((prev) => {
        const existingIds = new Set(prev.map(q => q.id || q._id));
        const newQuestions = data.questions.filter(q => !existingIds.has(q.id || q._id));

        // Merge and sort by `createdAt` (newest first)
        return [...prev, ...newQuestions].sort((a, b) => (b.timestamp?._seconds || 0) - (a.timestamp?._seconds || 0));

      });

      setFilteredQuestions((prev) => {
        const existingIds = new Set(prev.map(q => q.id || q._id));
        const newQuestions = data.questions.filter(q => !existingIds.has(q.id || q._id));

        // Merge and sort by `createdAt` (newest first)
        return [...prev, ...newQuestions].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });

    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  }, [page, loading, hasMore]);

  useEffect(() => {
    fetchQuestionsData();
  }, [page]); // Removed fetchQuestionsData dependency

  useEffect(() => {
    let timeout;
    const handleScroll = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight) {
          setPage((prev) => (hasMore ? prev + 1 : prev)); // Ensure `hasMore` is checked
        }
      }, 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);



  useEffect(() => {
    if (questions.length === 0) return;

    const uniqueUids = [...new Set(questions.map((q) => q.user_id))].filter(uid => !users[uid]); // Only fetch new users

    if (uniqueUids.length === 0) return;

    const fetchUsersData = async () => {
      const userMap = { ...users };

      await Promise.all(uniqueUids.map(async (uid) => {
        if(uid){
          try {
            const response = await fetch(`https://skill-guru-backend.vercel.app/currentuser/currentuser/${uid}`);
            if (!response.ok) throw new Error("User not found");
  
            const userData = await response.json();
            userMap[uid] = {
              fullName: userData.user.fullName || "Unknown",
              profileImageUrl: userData.user.profileImageUrl || "https://via.placeholder.com/60",
            };
          } catch (error) {
            console.error(`Error fetching user ${uid}:`, error);
            userMap[uid] = { fullName: "Unknown", profileImageUrl: "https://via.placeholder.com/60" };
          }
        }
        
      }));

      setUsers(userMap);
    };

    fetchUsersData();
  }, [questions]);


  // Infinite Scroll Detection
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.offsetHeight
      ) {
        setPage((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Search Filtering
  useEffect(() => {
    if (!searchQuery) {
      setFilteredQuestions(questions);
    } else {
      setFilteredQuestions(
        questions.filter((q) =>
          q.question.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, questions]);

  return (
    <div className="post-question-container">
      {/* Background Circles */}
      <div className="background-circles">
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="circle three"></div>
      </div>


      <MainHome />

      <div className="container" style={{ marginTop: "80px" }}>
        <div>
          {/* Questions Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              height: "106px",
            }}
          >
            <span
              style={{
                background: "linear-gradient(90.69deg, #C34D1E 38.05%, #314471 72.38%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: isMobile ? "27px" : "48px",
              }}
            >
              Questions
            </span>
            <div
              onClick={() => navigate("/post-question")}
              style={{
                background: "rgba(202, 85, 42, 1)",
                gap: "8px",
                height: isMobile ? "42px" : "64px",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: isMobile ? "140px" : "250px",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontWeight: "500",
                  fontSize: isMobile ? "13px" : "24px",
                  color: "rgba(255, 255, 255, 1)",
                }}
              >
                <i className="fa-solid fa-plus"></i> Post Question
              </span>
            </div>
          </div>

          {filteredQuestions.length > 0 ? (
            filteredQuestions.map((item) => (
              <div
                key={item.id || item._id}
                className="p-4 p-md-5 mb-4 border shadow"
                style={{ background: "rgba(255, 255, 255, 1)", borderRadius: "20px", display: "flex", flexDirection: 'column', flexWrap: "wrap" }}
              >
                <div className="col-lg-12" style={{ minHeight: "328px", width: "80vw" }}>
                  <div
                    className="profile"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <img
                        src={users[item.user_id]?.profileImageUrl}
                        alt="User profile"
                        style={{
                          height: isMobile ? "60px" : "70px",
                          width: "70px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                      <p style={{ fontSize: isMobile ? "20px" : "24px", fontWeight: "500" }}>
                        {users[item.user_id]?.fullName || "Unknown"}
                      </p>
                    </div>
                    <img src={icon} alt="Options" style={{ height: "40px", cursor: "pointer" }} />
                  </div>
                  <p style={{ fontSize: "32px", fontWeight: "500", lineHeight: "40px", display: "flex", flexWrap: "wrap" }}>
                    {isMobile ? item.question.substring(0, 30) + "..." : item.question}
                  </p>

                  <div>
                    {item.skills.slice(0, 3).map((skill, index) => (
                      <span key={index}>{skill}{index < 2 ? ', ' : ''}</span>
                    ))}
                    {item.skills.length > 3 && '...'}
                  </div>


                  {/* ✅ Restored Answer Button */}
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div
                      onClick={() => navigate("/answers", { state: { item, users } })}
                      style={{
                        background: "rgba(72, 94, 146, 1)",
                        gap: "8px",
                        height: "64px",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        width: "150px",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ fontWeight: "500", fontSize: "24px", color: "#FFF" }}>
                        Answer
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            loading && <p style={{ width: '100vw', height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "18px", maxWidth: "100vw" }} >Loading  questions...</p>
          )}


        </div>
      </div>
    </div>
  );
}

export default Postquestion;
