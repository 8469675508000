import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import postIcon from '../Postquetion_popup/post.png';
import deleteIcon from '../Postquetion_popup/delete.png';
import Newnavbar from '../../components/NewNav/Newnavbar';
import { toast } from 'react-toastify';

function PostAnswer_pop_up() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [AnswerText, setAnswerText] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { item } = location.state || {};

    const uid = localStorage.getItem('uid');

    const handleAnswerChange = (event) => {
        setAnswerText(event.target.value);
    };

    const handleImageUpload = (event) => {
        setImage(event.target.files[0]);
    };

    const handlePostAnswer = async () => {
        if (!AnswerText) {
            alert('Please enter an answer.');
            return;
        }

        const formData = new FormData();
        formData.append("uid", uid);
        formData.append("AnswerText", AnswerText);
        formData.append("questionid", item.id);
        if (image) formData.append("image", image);

        try {
            setLoading(true);
            const response = await fetch('https://skill-guru-backend.vercel.app/post-answer/post-answer', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Unknown error occurred');

            toast.info("Answer posted successfully.");

            setAnswerText(""); // Clear input after success
            setImage(null);
        } catch (error) {
            console.error('Error:', error.message);
            toast.info('Failed to post answer. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '30px', }}>
            <div className='border shadow' style={{ background: 'white', width: '797px', height: '620px', borderRadius: '20px', display: 'flex', flexDirection: 'column', padding: isMobile ? '20px' : '60px' }}>
                <p style={{ fontWeight: '600', fontSize: '24px', color: 'black' }}>Post Answer</p>
                
                <div style={{ position: 'relative', width: '100%', marginTop: '60px' }}>
                    <span style={{ position: 'absolute', top: '-10px', left: '10px', fontWeight: '400', fontSize: '16px', color: 'rgba(73, 69, 79, 1)', background: '#fff', padding: '0 5px' }}>
                        Your Answer
                    </span>
                    <textarea
                        placeholder="Type Your Answer"
                        value={AnswerText}
                        onChange={handleAnswerChange}
                        style={{
                            width: '100%',
                            padding: '18px 15px 95px 15px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            resize: 'none',
                            outline: 'none'
                        }}
                    />
                </div>

                <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginTop: '10px' }} />
                {image && <p style={{ marginTop: '10px' }}>Selected Image: {image.name}</p>}

                <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', marginTop: '40px' }}>
                    <button
                        style={{ background: 'rgba(202, 85, 42, 1)', borderRadius: '30px', padding: '10px 20px', color: '#fff', fontWeight: '500', cursor: 'pointer' }}
                        onClick={handlePostAnswer}
                        disabled={loading}
                    >
                        {loading ? "Posting..." : <><img src={postIcon} style={{ height: '20px', marginRight: '8px' }} alt="" /> Post</>}
                    </button>
                </div>
            </div>
        </div>
        </>
        
    );
}

export default PostAnswer_pop_up;
